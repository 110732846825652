import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { ChangeEvent } from "react";

interface WalletsPayProps {
  phoneNumber: string;
  onPhoneNumberChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function WalletsPay({
  phoneNumber,
  onPhoneNumberChange,
}: WalletsPayProps) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-1.5 w-full px-4 py-2 md:py-c13 text-13.18 text-label-text bg-label1 rounded-30p">
      <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
        <Img
          src="/images/deposit/wallet_phone.svg"
          alt="swap-left"
          className="object-contain w-full h-full"
          loader={<span className="inline-block w-full h-full" />}
        />
      </div>
      <div className="flex flex-col w-full">
        <span className="text-10 md:text-11.12 text-grey3">
          {t("your-phone")}
        </span>
        <input
          type="number"
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-white"
        />
      </div>
    </div>
  );
}
