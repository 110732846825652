import { useNotification } from "../context/NotificationContext";

const Notification = () => {
  const { notification } = useNotification();

  if (!notification) return null;

  const { message, type } = notification;
  const backgroundColor = type === "success" ? "green" : "red";

  return (
    <div
      style={{
        position: "fixed",
        top: 20,
        right: 20,
        background: backgroundColor,
        padding: 10,
        borderRadius: 5,
        color: "white",
      }}
    >
      {message}
    </div>
  );
};

export default Notification;
