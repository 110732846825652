import React from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { PaymentButtonProps } from "../types";

const PaymentButton: React.FC<PaymentButtonProps> = ({
  activeButton,
  isChecked,
  isGiftCardButtonActive,
  isWalletsButtonActive,
  isSkinLoading,
  onClick,
  isActive,
}) => {
  const { t } = useTranslation();

  const isButtonDisabled =
    !isActive ||
    (activeButton === "Wallets" && !isWalletsButtonActive) ||
    (activeButton === "Gift Card" && !isGiftCardButtonActive) ||
    !isChecked;

  const handleClick = () => {
    if (!isButtonDisabled) {
      onClick();
    }
  };

  if (activeButton === "Gift Card") {
    return (
      <button
        onClick={handleClick}
        className={`w-full flex justify-center items-center p-4 mt-c14 ${
          !isButtonDisabled
            ? "bg-red4 hover:bg-red5 text-dark4"
            : "bg-gray-400 text-gray-600 cursor-not-allowed"
        } text-15.28 font-bold rounded-30p order-5`}
        disabled={isButtonDisabled}
      >
        {t("apply-code")}
        <div className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 ml-1.5">
          <Img
            src="/images/deposit/wallet_icon.svg"
            alt={t("wallet-icon")}
            className="object-contain w-full h-full"
            loader={<span className="inline-block w-full h-full" />}
          />
        </div>
      </button>
    );
  }

  return (
    <button
      onClick={handleClick}
      className={`w-full flex justify-center items-center p-4 mt-c14 ${
        !isButtonDisabled
          ? activeButton === "Wallets"
            ? "bg-yellow4 hover:bg-yellow6 text-dark4"
            : "bg-yellow4 hover:bg-yellow6 text-dark4"
          : "bg-gray-400 text-gray-600 cursor-not-allowed"
      } text-15.28 font-bold rounded-30p order-5`}
      disabled={isButtonDisabled}
    >
      {t("refill-balance")}

      {isSkinLoading ? (
        <div className="w-5 h-5 border-t-2 border-b-2 border-gray-800 rounded-full animate-spin ml-c7"></div>
      ) : (
        <Img
          src="/images/panel-header/plus.svg"
          alt="login-steam"
          width={20}
          height={20}
          loader={
            <span
              style={{
                width: 20,
                height: 20,
              }}
              className="ml-c7"
            />
          }
          className="ml-c7"
        />
      )}
    </button>
  );
};

export default PaymentButton;
