import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface ApiResponse<T> {
  success: boolean;
  error?: string;
}

type ErrorHandler = (error: string) => void;

export class Api {
  public readonly BASE_URL = process.env.REACT_APP_BASE_URL || "";

  private formatError(error: unknown): string {
    if (axios.isAxiosError(error)) {
      // Обработка ошибок от axios
      if (error.response) {
        return (
          error.response.data?.message ||
          error.response.statusText ||
          "Unknown error occurred"
        );
      } else {
        return error.message || "Network error occurred";
      }
    } else if (error instanceof Error) {
      // Обработка ошибок, не связанных с axios, но являющихся экземплярами Error
      return error.message || "Unknown error occurred";
    } else {
      // Обработка ошибок, которые не являются экземплярами Error
      return "Unknown error occurred";
    }
  }

  private async checkResponse<T>(
    res: AxiosResponse<ApiResponse<T>>
  ): Promise<T> {
    if (res.status !== 200) {
      throw new Error("Server error");
    }
    if (!res.data.success) {
      throw new Error(res.data.error || "Unknown server error");
    }
    return res.data as T;
  }

  public async request<T>(
    endpoint: string,
    options: AxiosRequestConfig = {},
    errorHandler?: ErrorHandler
  ): Promise<T> {
    try {
      const res = await axios({
        url: this.BASE_URL + endpoint,
        withCredentials: true,
        ...options,
      });
      return await this.checkResponse<T>(res);
    } catch (error: unknown) {
      const errorMessage = this.formatError(error);
      if (errorHandler) {
        errorHandler(errorMessage);
      }
      throw new Error(errorMessage);
    }
  }
}

const api = new Api();
export default api;
