import api from "../api";

interface TradeUrlResponse {
  success: boolean;
}

export const sendTradeUrl = async (
  trade_url: string
): Promise<TradeUrlResponse> => {
  return api.request<TradeUrlResponse>("user/tradeURL", {
    method: "POST",
    data: { trade_url },
  });
};
