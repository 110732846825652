import React, { useEffect, useState } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import { myProfileItems } from "../../utils/data/my-profile-items";
import { UserCardProfile } from "./userCardProfile/UserCardProfile";
import SkeletonUserCardProfile from "./userCardProfile/SkeletonUserCardProfile";

interface MenuDepositProps {}

const UserItemsProfile: React.FC<MenuDepositProps> = React.memo(() => {
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  // const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className="flex gap-5 items-center space-x-4 mb-4">
        <div className="flex items-center">
          <div className="min-w-7 min-h-7 md:w-c34 md:h-c34 mr-2.5">
            <Img
              src="/images/profile/items.svg"
              alt="deposit-logo"
              className="object-contain w-full h-full"
              loader={<span className="w-full h-full mr-2.5" />}
            />
          </div>
          <p className="text-14 lg:text-23.28 text-white font-semibold">
            {t("user-items")}
          </p>
        </div>
      </section>
      <section className="max-w-[1360px] w-full mx-auto">
        <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-7 gap-1.5 sm:gap-3">
          {loading
            ? Array(28)
                .fill(0)
                .map((_, index) => <SkeletonUserCardProfile key={index} />)
            : myProfileItems.map((item, index) => (
                <UserCardProfile key={index} casesItem={item} />
              ))}
        </div>
      </section>
    </>
  );
});

export default UserItemsProfile;
