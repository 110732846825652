import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { ChangeEvent } from "react";

interface GiftCardPayProps {
  giftCode: string;
  onGiftCodeChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function GiftCardPay({
  giftCode,
  onGiftCodeChange,
}: GiftCardPayProps) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-1.5 items-center w-full px-4 py-2 md:py-c13 text-13.18 text-label-text bg-label1 rounded-30p">
      <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
        <Img
          src="/images/deposit/gift-icon.svg"
          alt="swap-left"
          className="object-contain w-full h-full"
          loader={<span className="inline-block w-full h-full" />}
        />
      </div>
      <div className="flex flex-col w-full">
        <input
          type="text"
          placeholder={t("gift-code")}
          value={giftCode}
          onChange={onGiftCodeChange}
          className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-grey3"
        />
      </div>
    </div>
  );
}
