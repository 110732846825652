import React, { useEffect } from "react";
import { useUserStore } from "../../zustand/stores/userStore";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
};

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const isLoggedIn = useUserStore.use.isLoggedIn();
  const needsRedirect = useUserStore.use.needsRedirect();
  const setNeedsRedirect = useUserStore.use.setNeedsRedirect();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      const steamLoginUrl = `${process.env.REACT_APP_BASE_URL}steam/login`;
      window.location.replace(steamLoginUrl);
    } else if (needsRedirect) {
      if (location.pathname !== "/") {
        navigate("/"); // Редирект на главную страницу после разлогинивания
      }
      setNeedsRedirect(false);
    }
  }, [
    isLoggedIn,
    needsRedirect,
    setNeedsRedirect,
    navigate,
    location.pathname,
  ]);

  return isLoggedIn ? <>{children}</> : null;
};

export default PrivateRoute;
