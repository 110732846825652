import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Img } from "react-image";
import { useUserStore } from "../../zustand/stores/userStore";

interface BalanceSectionProps {}

const BalanceSection: React.FC<BalanceSectionProps> = () => {
  const { t } = useTranslation();
  const symbol = useUserStore.use.getSymbol()();
  const balance = useUserStore.use.getBalance()();

  return (
    <div className="flex flex-col md:justify-center gap-3 p-c14 md:p-6 bg-dark5 rounded-14p md:h-full">
      <div className="flex gap-2 md:gap-c23 justify-between">
        <div className="flex flex-col md:gap-1.5 w-c100">
          <p className="text-11 md:text-14 text-grey5 font-medium">
            {t("your-balance")}
          </p>
          <p className="text-13 md:text-18 text-yellow4 font-bold">
            {symbol}
            {balance ?? 0}
          </p>
        </div>
        <Link to="/deposit">
          <button className="flex justify-between items-center px-2 py-2 md:px-5 md:py-c13 bg-yellow4 hover:bg-yellow6 text-dark3 text-14.24 font-bold rounded-full md:rounded-30p">
            <span className="hidden md:block">{t("deposit")}</span>
            <div className="inline-block w-c18 h-c18 md:w-6 md:h-6 md:ml-c7">
              <Img
                src="/images/panel-header/plus.svg"
                alt="login-steam"
                className="object-cover w-full h-full"
                loader={
                  <span className="inline-block w-full h-full md:ml-c7" />
                }
              />
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BalanceSection;
