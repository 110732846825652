import { useTranslation } from "react-i18next";

export default function PayText() {
  const { t } = useTranslation();

  return (
    <div className="mt-c22 flex flex-col gap-6 text-12 sm:text-13.18">
      <p>{t("deposit-p1")}</p>
      <p>
        {t("deposit-p2-1")}
        <span className="text-red4">support@plgcase.com</span>
        {t("deposit-p2-2")}
      </p>
    </div>
  );
}
