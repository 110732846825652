import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useNotification } from "../../../context/NotificationContext";
import { useModalsStore } from "../../../zustand/stores/modalsStore";
import { useDepositStore } from "../../../zustand/stores/depositStore";

interface Network {
  id: string;
  name: string;
}

const networks: Network[] = [
  { id: "ethereum", name: "Ethereum" },
  { id: "bitcoin", name: "Bitcoin" },
  { id: "ripple", name: "Ripple" },
];

export default function CryptoPay() {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [showWallet, setShowWallet] = useState(false);
  const [generatedWallet, setGeneratedWallet] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const setIsQrCryptoModalOpen = useModalsStore(
    (state) => state.setIsQrCryptoModalOpen
  );
  const setWalletAddress = useDepositStore((state) => state.setWalletAddress);

  const generateWallet = useCallback(() => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const walletLength = 20;

    for (let i = 0; i < walletLength; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    switch (selectedNetwork) {
      case "ethereum":
        return `0x${result}-TEST`;
      case "bitcoin":
        return `1${result}-TEST`;
      case "ripple":
        return `r${result}-TEST`;
      default:
        return result + "-TEST";
    }
  }, [selectedNetwork]);

  useEffect(() => {
    if (showWallet && selectedNetwork) {
      setGeneratedWallet(generateWallet());
    }
  }, [showWallet, selectedNetwork, generateWallet]);

  const handleButtonClick = () => {
    if (selectedNetwork) {
      setShowWallet(true);
    } else {
      addNotification(t("select-network-error"), "error");
    }
  };

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(generatedWallet)
      .then(() => {
        addNotification(t("wallet-copied"), "success");
      })
      .catch((error) => {
        addNotification(t("copy-error"), "error");
      });
  }, [generatedWallet, addNotification, t]);

  const handleQrClick = () => {
    setWalletAddress(generatedWallet);
    setIsQrCryptoModalOpen(true);
  };

  return (
    <div className="w-full flex flex-col items-center gap-c9">
      <div className="w-full relative">
        <div
          className="w-full flex justify-between items-center px-4 py-2 bg-c-grey2 border border-dotted border-c-grey3 rounded-19p cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-white">
            {selectedNetwork
              ? networks.find((n) => n.id === selectedNetwork)?.name ||
                t("select-network")
              : t("select-network")}
          </span>
          <svg
            className="w-4 h-4 fill-current ml-2 md:ml-c18"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.0509 4C2.51393 4 2.22694 4.63245 2.58054 5.03656L5.34145 8.1919C5.69006 8.59031 6.30985 8.59031 6.65846 8.1919L9.41938 5.03657C9.77297 4.63245 9.48599 4 8.94901 4H3.0509Z"
              fill="#9B9FB7"
            />
          </svg>
        </div>
        <div
          className={`absolute w-full mt-1 bg-c-grey3 border border-c-grey3 rounded-19p z-10 transition-all duration-100 ${
            isOpen ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          {networks.map((network) => (
            <div
              key={network.id}
              className="px-4 py-2 hover:bg-c-grey2 cursor-pointer text-white"
              onClick={() => {
                setSelectedNetwork(network.id);
                setIsOpen(false);
              }}
            >
              {network.name}
            </div>
          ))}
        </div>
      </div>

      {showWallet ? (
        <div className="w-full flex items-center justify-between gap-c9">
          <div className="w-full flex items-center justify-between px-4 py-c13 bg-yellow7 text-yellow4 text-12.18 font-bold border border-dotted border-yellow4 rounded-30p">
            <span>{generatedWallet}</span>
            <button
              onClick={handleCopy}
              className="inline-block w-5 h-5 md:w-c22 md:h-c22 ml-2"
              aria-label={t("copy-wallet-number")}
            >
              <Img
                src={"/images/deposit/copy.svg"}
                alt={t("copy")}
                className="object-cover w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </button>
          </div>
          <div className="bg-dark5 rounded-full w-с50 h-с50 flex items-center justify-center p-c13">
            <button
              className="flex items-center justify-center w-5 h-5 md:w-6 md:h-6"
              onClick={handleQrClick}
            >
              <Img
                src={"/images/deposit/qr.svg"}
                alt={t("qr-code")}
                className="object-contain w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </button>
          </div>
        </div>
      ) : (
        <button
          className="w-full flex justify-center items-center px-4 py-c11 bg-red4 hover:bg-red5 text-dark4 text-13.20 md:text-15.28 font-bold rounded-30p"
          onClick={handleButtonClick}
        >
          {t("generate-wallet")}
          <div className="flex-shrink-0 w-5 h-5 sm:w-6 sm:h-6 ml-1.5">
            <Img
              src="/images/deposit/wallet_icon.svg"
              alt={t("wallet-icon")}
              className="object-contain w-full h-full"
              loader={<span className="inline-block w-full h-full" />}
            />
          </div>
        </button>
      )}
    </div>
  );
}
