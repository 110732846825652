import UserProfile from "../../../components/userProfile/UserProfile";
import UserItemsProfile from "../../../components/userProfile/UserItemsProfile";

export default function User() {
  return (
    <main className="flex flex-col w-full md:max-w-main md:mx-auto pt-4 md:pt-c30 pb-c26 md:pb-c66 main-mx">
      <UserProfile />
      <UserItemsProfile />
    </main>
  );
}
