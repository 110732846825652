import { SkinTypeSlider } from "../../../utils/types";
import SliderVertical from "./SliderVertical";
import { useState, useEffect } from "react";
import { sellSkin } from "../../../api/apiSellSkin";
import { useNotification } from "../../../context/NotificationContext";
import { useUserStore } from "../../../zustand/stores/userStore";
import { useTranslation } from "react-i18next";

interface MultiSliderBlockProps {
  count: number;
  skins: SkinTypeSlider[];
  initialSpins: (string | undefined)[];
  wonSkinIds: number[];
  wonSkinPrices: string[]; // цены выигрышных скинов
  onSpinEnd: () => void;
  isFastMode: boolean;
  handleTryAgain: () => void;
}

export default function MultiSliderBlock({
  count,
  skins,
  initialSpins,
  wonSkinIds,
  wonSkinPrices,
  onSpinEnd,
  isFastMode,
  handleTryAgain,
}: MultiSliderBlockProps) {
  const [soldSkins, setSoldSkins] = useState<Set<number>>(new Set());
  const { addNotification } = useNotification();
  const updateBalance = useUserStore((state) => state.updateBalance);
  const { t } = useTranslation();

  useEffect(() => {
    const spinAllSliders = () => {
      const event = new Event("startSpinning");
      window.dispatchEvent(event);
    };

    setTimeout(spinAllSliders, 100);
  }, [initialSpins]);

  const handleSellSkin = async (skinId: number) => {
    try {
      const response = await sellSkin([skinId]);
      if (response.success) {
        updateBalance(response.new_balance);
        setSoldSkins((prevSoldSkins) => {
          const updatedSoldSkins = new Set(prevSoldSkins).add(skinId);

          // Проверяем, если все скины проданы, вызываем handleTryAgain
          if (updatedSoldSkins.size === wonSkinIds.length) {
            handleTryAgain(); // Вызываем сброс состояния
          }

          return updatedSoldSkins;
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error selling skin:", error);
        const errorMessage = error.message || "An unexpected error occurred -1";
        addNotification(errorMessage, "error");
      } else {
        console.error("Error selling skin -2:", error);
      }
    }
  };

  return (
    <div className="relative">
      <div className="relative w-full flex items-center justify-evenly bg-dark1 overflow-hidden rounded-20p">
        {[...Array(count)].map((_, index) => (
          <SliderVertical
            key={index}
            skins={skins}
            initialSpin={initialSpins[index]}
            wonSkinId={wonSkinIds[index]} // Передаем один ID для каждого слайдера
            wonSkinPrice={wonSkinPrices[index]} // Передаем цены для выигрышных скинов
            onSpinEnd={onSpinEnd}
            uniqueId={index}
            isFastMode={isFastMode}
            onSellSkin={handleSellSkin}
            isSold={(skinId) => soldSkins.has(skinId)}
          />
        ))}
      </div>
      <div className="arrow-left"></div>
      <div className="arrow-right"></div>
    </div>
  );
}
