import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "js-cookie";
import ru from "./utils/language/ru.json";
import en from "./utils/language/en.json";

const resources = {
  ru: { translation: ru },
  en: { translation: en },
};

// Функция для инициализации i18n
const initI18n = () => {
  const savedLanguage = Cookies.get("selectedLanguage") || "ru";

  i18n.use(initReactI18next).init({
    resources,
    lng: savedLanguage,
    fallbackLng: "ru",
    debug: false,
    nonExplicitSupportedLngs: true,
    detection: {
      order: ["cookie", "navigator"],
    },
    interpolation: {
      escapeValue: false,
    },
    locales: ["en", "ru"],
  });
};

// Вызываем инициализацию
initI18n();

// Функция для изменения языка
export const changeLanguage = (language) => {
  Cookies.set("selectedLanguage", language);
};

export default i18n;
