import React from "react";
import { Img } from "react-image";

interface AnswerProps {
  content: React.ReactNode;
}

const Answer: React.FC<AnswerProps> = ({ content }) => (
  <div className="p-7 bg-dark5 rounded-2xl text-grey4 font text-xs sm:text-sm mb-5">
    {content}
  </div>
);

export default function Faq() {
  const faqData = [
    {
      question: "How do I check if I already have an account at Twin?",
      answer: <>Ответ на вопрос 1</>,
    },
    {
      question: "How to use a Bonus Promo Code",
      answer: <>Ответ на вопрос 2</>,
    },
    {
      question: "How do I participate in the Lottery?",
      answer: (
        <>
          <p className="mb-2">
            You can check if you have an account by restoring access to it using
            the "Reset password" option.
          </p>
          <p className="mb-2">
            If you don't receive the message, there is probably no account
            associated with the email address or mobile number you have entered.
            If you are sure you already have an account which you can't access
            again, please contact customer support. If you have not opened an
            account yet and you did not get an email with the instructions on
            how to access it, then open a new account to start playing at Twin.
          </p>
          <p className="mb-2">
            When creating a ticket please specify the following information:
          </p>
          <ul className="list-disc ml-6 mb-2">
            <li>Payment method (Card, Skrill, Skinpay, etc.)</li>
            <li>Total amount in USD</li>
            <li>The time of the transaction</li>
            <li>
              If possible attach a screenshot of a receipt from your
              bank/e-wallet
            </li>
          </ul>
          <p className="mb-2">
            You will receive a message with instructions to your email or mobile
            phone within a few minutes.
          </p>
          <p className="text-green6">
            Go to the home page and click on "Log in", then click on "Forgot
            your password?". In the window that opens, enter your e-mail address
            or mobile number and click the "Next" button.
          </p>
        </>
      ),
    },
    {
      question: "Where can I find deposit promo codes?",
      answer: <>Ответ на вопрос 4</>,
    },
    {
      question: "How do I use the Deposit Bonus (case, item, percentage)?",
      answer: <>Ответ на вопрос 5</>,
    },
    {
      question:
        "Why did one person win the battle, when the same drop was shared by multiple participants?",
      answer: <>Ответ на вопрос 6</>,
    },
    {
      question: "How to cancel an item request (how to cancel a trade)",
      answer: <>Ответ на вопрос 7</>,
    },
    {
      question: "How do I check if I already have an account at Twin?",
      answer: <>Ответ на вопрос 8</>,
    },
  ];

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <main className="w-full flex flex-col max-w-main mx-auto pt-4 md:pt-c30 pb-4 md:pb-c66 main-mx">
      <h1 className="text-sm md:text-24 text-white">F.A.Q</h1>
      <div className="flex mt-2.5 md:mt-4 mb-2.5 md:mb-6 rounded-2xl bg-fon6 p-2.5 md:p-3 relative md:pb-8 md:max-w-main">
        <div className="flex flex-col gap-2.5">
          <h2 className="text-base md:text-2xl font-extrabold text-white">
            Need Help?
          </h2>
          <p className="text-10.13 md:text-13.18 text-grey5 font-semibold max-w-974">
            Any questions? Check out the answers to the most popular questions
            related to the operation of the DragonMoney website. Most likely,
            you will find the answer to your question here. If you haven't found
            the answer to your question, contact our support team. We will be
            happy to help you
          </p>
        </div>
        <Img
          src="/images/faq/pic.svg"
          alt="faq"
          width={209}
          height={214}
          className="absolute right-4 bottom-0 hidden md:block"
        />
      </div>
      {faqData.map((faq, index: number) => (
        <div
          key={index}
          className="bg-dark4 mt-1 rounded-14p px-5 text-grey4 font text-xs sm:text-sm font-semibold"
        >
          <div
            className={`py-5 cursor-pointer ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => toggleQuestion(index)}
          >
            <div className="flex gap-2.5">
              <Img
                src={
                  activeIndex === index
                    ? "/images/faq/minus.svg"
                    : "/images/faq/plus.svg"
                }
                alt="faq"
                width={24}
                height={24}
              />
              <p>{faq.question}</p>
            </div>
          </div>
          {activeIndex === index && <Answer content={faq.answer} />}
        </div>
      ))}
    </main>
  );
}
