import React, { useState } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import SwitchBlock from "./SwitchBlock";
import RangeSlider from "./RangeSlider"; // Импортируем компонент RangeSlider
import { useUserStore } from "../../zustand/stores/userStore";

interface Filters {
  searchQuery: string;
  available: boolean;
  priceRange: [number, number] | null;
}

interface FiltrProps {
  updateFilters: (newFilters: Partial<Filters>) => void; // Изменение типа здесь
}

const Filtr = ({ updateFilters }: FiltrProps) => {
  const { t } = useTranslation(); // Получение функции перевода
  const [isChecked, setIsChecked] = useState(false); // Состояние чекбокса
  const [resetRange, setResetRange] = useState(false); // Состояние для сброса диапазона цен
  const isLoggedIn = useUserStore.use.isLoggedIn();

  const handleToggle = () => {
    setIsChecked(!isChecked);
    // updateFilters({ available: !isChecked }); // Обновление фильтра в родительском компоненте
    if (isLoggedIn) {
      updateFilters({ available: !isChecked });
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilters({ searchQuery: e.target.value });
  };

  const handleClearFilters = () => {
    updateFilters({
      searchQuery: "",
      available: false,
      priceRange: [0, 40000],
    });
    setIsChecked(false); // Сброс состояния чекбокса
    setResetRange(true); // Установка состояния для сброса диапазона цен
    // Добавляем очистку поля ввода
    const inputElement = document.querySelector(
      'input[name="q"]'
    ) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = "";
      // Сброс resetRange после короткой задержки
      setTimeout(() => setResetRange(false), 100);
    }
  };

  // Обработчик для обновления диапазона цен
  const handlePriceRangeChange = (newRange: [number, number]) => {
    updateFilters({ priceRange: newRange });
  };

  return (
    <>
      {/* Desktop */}
      <section className="hidden md:flex justify-between items-center bg-dark4 rounded-2xl p-5 lg:min-w-c1023 w-full">
        <div className="flex md:flex-col lg:flex-row justify-between items-center w-full">
          <div className="flex gap-5 items-center space-x-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <label className="relative">
                <input
                  type="text"
                  name="q"
                  placeholder={t("search-cases")}
                  aria-label="Search cases"
                  className="w-c260 h-c50 pl-c18 pr-4 py-c13 text-13.18 text-label-text bg-label1 hover:bg-h-label1 rounded-30p focus:outline-none focus:bg-f-label1"
                  onChange={handleSearchChange}
                />
                <button
                  type="submit"
                  className="flex items-center absolute inset-y-0 right-0 pr-3"
                >
                  <Img
                    src={"/images/filtr/seach.svg"}
                    alt="search"
                    width={24}
                    height={24}
                  />
                </button>
              </label>
            </form>
            <RangeSlider
              onChange={handlePriceRangeChange}
              resetRange={resetRange}
            />{" "}
            {/* Передача обработчика и состояния сброса в RangeSlider */}
          </div>
          <div className="flex gap-5 items-center">
            <div className="flex gap-c11 items-center">
              <p className="text-13.18 font-medium text-blue">
                {t("available")}
              </p>
              <SwitchBlock isChecked={isChecked} handleToggle={handleToggle} />
            </div>
            <button
              className="flex justify-between items-center px-4 py-2.5 bg-dark5 text-grey4 text-13.20 font-bold rounded-30p"
              onClick={handleClearFilters}
            >
              {t("clear")}
              <Img
                src="/images/filtr/clear.svg"
                alt="clear"
                width={20}
                height={20}
                loader={
                  <span style={{ width: 20, height: 20 }} className="ml-c7" />
                }
                className="ml-c7"
              />
            </button>
          </div>
        </div>
      </section>

      {/* Mobile */}
      <section className="bg-dark4 rounded-2xl p-4 w-full md:hidden">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-5">
          <div className="flex flex-col md:flex-row gap-5 items-center w-full md:w-auto">
            <div className="flex flex-row gap-2 w-full md:w-auto">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="relative w-2/4 md:w-c260 flex-grow"
              >
                <input
                  type="text"
                  name="q"
                  placeholder={t("search-cases")}
                  aria-label="Search cases"
                  className="w-full h-10 pl-c18 pr-4 py-c13 text-13.18 text-label-text bg-label1 hover:bg-h-label1 rounded-30p focus:outline-none focus:bg-f-label1"
                  onChange={handleSearchChange}
                />
                <button
                  type="submit"
                  className="flex items-center absolute inset-y-0 right-0 pr-3"
                >
                  <Img
                    src={"/images/filtr/seach.svg"}
                    alt="search"
                    width={24}
                    height={24}
                  />
                </button>
              </form>
              <button
                className="flex justify-between items-center px-2.5 py-2.5 bg-dark5 text-grey4 text-12 font-bold rounded-30p min-w-c81 md:w-auto"
                onClick={handleClearFilters}
              >
                {t("clear")}
                <Img
                  src="/images/filtr/clear.svg"
                  alt="clear"
                  width={18}
                  height={18}
                  loader={
                    <span
                      style={{ width: 18, height: 18 }}
                      className="ml-1.5"
                    />
                  }
                  className="ml-1.5"
                />
              </button>
            </div>
            <RangeSlider
              onChange={handlePriceRangeChange}
              resetRange={resetRange}
            />{" "}
            {/* Передача обработчика и состояния сброса в RangeSlider */}
          </div>
          <div className="flex flex-col md:flex-row gap-5 items-center w-full md:w-auto">
            <div className="flex gap-c11 items-center w-full md:w-auto justify-center">
              <p className="text-13.18 font-medium text-blue">
                {t("available")}
              </p>
              <SwitchBlock isChecked={isChecked} handleToggle={handleToggle} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Filtr;
