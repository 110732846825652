export const cardsHeader = [
  {
    image: "/images/header/1.svg",
    name: "Safari Mesh",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Вася",
    id: 1,
    rarity: "rare1",
  },
  {
    image: "/images/header/2.svg",
    name: "Freehand",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Леонид",
    id: 2,
    rarity: "rare2",
  },
  {
    image: "/images/header/3.svg",
    name: "Urban Masked",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Иннокентий",
    id: 3,
    rarity: "rare2",
  },
  {
    image: "/images/header/4.svg",
    name: "Bloodshot",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Василиса",
    id: 4,
    rarity: "rare4",
  },
  {
    image: "/images/header/5.svg",
    name: "Heat",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Михаил",
    id: 5,
    rarity: "rare5",
  },
  {
    image: "/images/header/6.svg",
    name: "Scorched",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "FOX",
    id: 6,
    rarity: "rare6",
  },
  {
    image: "/images/header/7.svg",
    name: "Tiger Tooth",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Матвей",
    id: 7,
    rarity: "rare7",
  },
  {
    image: "/images/header/8.svg",
    name: "Survivor Z",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Захар",
    id: 8,
    rarity: "rare1",
  },
  {
    image: "/images/header/9.svg",
    name: "Pipe Down",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Иван",
    id: 9,
    rarity: "rare2",
  },
  {
    image: "/images/header/10.svg",
    name: "Meduza",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Петр",
    id: 10,
    rarity: "rare2",
  },
  {
    image: "/images/header/11.svg",
    name: "Deep Relief",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Александр",
    id: 11,
    rarity: "rare3",
  },
  {
    image: "/images/header/12.svg",
    name: "Jungle Thicket",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Павел",
    id: 12,
    rarity: "rare1",
  },
  {
    image: "/images/header/13.svg",
    name: "Sacrif",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Алексей",
    id: 13,
    rarity: "rare1",
  },
];

export const cardsHeader2 = [
  {
    image: "/images/header/2.svg",
    name: "Freehand",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Леонид",
    id: 2,
    rarity: "rare2",
  },
  {
    image: "/images/header/1.svg",
    name: "Safari Mesh",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Вася",
    id: 1,
    rarity: "rare1",
  },
  {
    image: "/images/header/4.svg",
    name: "Bloodshot",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Василиса",
    id: 4,
    rarity: "rare4",
  },
  {
    image: "/images/header/3.svg",
    name: "Urban Masked",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Иннокентий",
    id: 3,
    rarity: "rare2",
  },
  {
    image: "/images/header/6.svg",
    name: "Scorched",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "FOX",
    id: 6,
    rarity: "rare6",
  },
  {
    image: "/images/header/5.svg",
    name: "Heat",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Михаил",
    id: 5,
    rarity: "rare5",
  },
  {
    image: "/images/header/8.svg",
    name: "Survivor Z",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Захар",
    id: 8,
    rarity: "rare1",
  },
  {
    image: "/images/header/7.svg",
    name: "Tiger Tooth",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Матвей",
    id: 7,
    rarity: "rare7",
  },
  {
    image: "/images/header/10.svg",
    name: "Meduza",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Петр",
    id: 10,
    rarity: "rare2",
  },
  {
    image: "/images/header/9.svg",
    name: "Pipe Down",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Иван",
    id: 9,
    rarity: "rare2",
  },
  {
    image: "/images/header/12.svg",
    name: "Jungle Thicket",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Павел",
    id: 12,
    rarity: "rare1",
  },
  {
    image: "/images/header/11.svg",
    name: "Deep Relief",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Александр",
    id: 11,
    rarity: "rare3",
  },
  {
    image: "/images/header/13.svg",
    name: "Sacrif",
    caseName: "caseName",
    avatar: "/images/header/topimg.png",
    nickName: "Алексей",
    id: 13,
    rarity: "rare1",
  },
];
