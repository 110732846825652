import { useState } from "react";
import { Img } from "react-image";
import { useNavigate } from "react-router-dom";
import { CaseItemType } from "../../../utils/types";
import { useUserStore } from "../../../zustand/stores/userStore";

interface CardProps {
  cardItem: CaseItemType;
}

const CaseCard: React.FC<CardProps> = ({ cardItem }) => {
  const isLoggedIn = useUserStore((state) => state.isLoggedIn);
  const symbol = useUserStore.use.getSymbol()();
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();

    navigate(`/case/${cardItem.name_id}`);
  };

  return (
    <div className="cursor-pointer" onClick={handleClick}>
      <div className="relative flex flex-col items-center mx-auto w-full max-w-[164px] md:max-w-[272px]">
        <Img
          src={cardItem.image_url}
          alt={cardItem.name}
          width="164"
          height="164"
          className="object-cover md:w-[272px] md:h-[272px] transition-transform transform-gpu hover:scale-110"
        />

        <p className="md:text-15 text-12 text-grey5 font-medium mt-0.5 mb-0.5">
          {cardItem.name}
        </p>
        <p className="md:text-13.18 text-10 text-c-grey1 font-medium mb-3">
          {cardItem.skin_count} items
        </p>
        <div className="flex items-center gap-3">
          <button
            className={`flex items-center px-4 py-2.5 rounded-30p ${
              hoveredButton === "button" ? "bg-yellow6 " : "bg-yellow4"
            } transition-all duration-300`}
            onMouseEnter={() => setHoveredButton("button")}
            onMouseLeave={() => setHoveredButton(null)}
          >
            <p className="md:text-13.20 text-12 text-dark3 font-bold">
              {isLoggedIn ? symbol : "$"}
              {cardItem.price}
            </p>
          </button>
          <p className="md:text-13.18 text-12 text-red5 font-semibold line-through">
            {isLoggedIn ? symbol : "$"}
            {cardItem.skin_count}
          </p>
        </div>
        {/* {cardItem.new && ( 
        <Img
          src="/images/home/new.svg"
          alt="label"
          width={47}
          height={36}
          className="absolute top-3 left-3"
        />
        )}
         {cardItem.hot && ( 
        <Img
          src="/images/home/hot.svg"
          alt="label"
          width={36}
          height={36}
          className="absolute top-3 left-3"
        />
        )}  */}
      </div>
    </div>
  );
};

export { CaseCard };
