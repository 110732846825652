import { ItemCard } from "./ItemCard/ItemCard";
import { Img } from "react-image";
import SkeletonItemsCard from "./ItemCard/SkeletonItemsCard";
import { SkinTypeSlider } from "../../utils/types";

interface CaseContentProps {
  loading: boolean;
  skins: SkinTypeSlider[];
}

export default function CaseContent({ loading, skins }: CaseContentProps) {
  return (
    <>
      <div className="flex justify-center items-center gap-2.5 mb-2 sm:mb-7">
        <div className="inline-block w-c26 h-c26 sm:w-c30 sm:h-c30">
          <Img
            src="/images/cases/layer.svg"
            alt="layer"
            className="object-cover w-full h-full"
            loader={<span className="inline-block w-full h-full" />}
          />
        </div>
        <h2 className="text-16 sm:text-24 text-white font-bold">
          Case content
        </h2>
      </div>
      <div className="max-w-[1360px] w-full mx-auto">
        <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-7 gap-1.5 sm:gap-3">
          {loading
            ? Array(28)
                .fill(0)
                .map((_, index) => <SkeletonItemsCard key={index} />)
            : skins.map((item, index) => (
                <ItemCard key={index} casesItem={item} />
              ))}
        </div>
      </div>
    </>
  );
}
