import { useState } from "react";
import { Img } from "react-image";
import { menuItems } from "../../utils/data/menu-items";
import { Menu } from "../Menu";
import { SocSeti } from "../SocSeti";

const GamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div
        className="flex items-center justify-center border border-solid w-c34 hc34 border-dark7 rounded-30p ml-2 p-2 cursor-pointer"
        onClick={toggleMenu}
      >
        <Img
          src="/images/footer/burger-bot-menu.svg"
          alt="bottom-menu"
          width={16}
          height={16}
          className="inline-block"
        />
      </div>
      {isOpen && (
        <div className="flex flex-col items-center absolute -mt-c200 right-0 bg-dark5 shadow-custom rounded-14p p-c14">
          <Menu menuItems={menuItems} onLinkClick={toggleMenu} />
          <div className="flex gap-2.5 items-center">
            <SocSeti />
          </div>
        </div>
      )}
    </>
  );
};

export { GamburgerMenu };
