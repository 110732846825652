import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import { useNotification } from "../../context/NotificationContext";
import { sendTradeUrl } from "../../api/profile/apiTradeUrl";
import { useUserStore } from "../../zustand/stores/userStore";

interface TradeUrlProps {}

const TradeUrl: React.FC<TradeUrlProps> = React.memo(() => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [inputValue, setInputValue] = useState<string>("");
  const [textSaved, setTextSaved] = useState<boolean>(false);

  // Получаем userData из хранилища
  const userData = useUserStore.use.userData();

  // Используем useEffect для установки trade_url в инпут при загрузке компонента
  useEffect(() => {
    if (userData && userData.trade_url) {
      setInputValue(userData.trade_url);
      setTextSaved(true);
    }
  }, [userData]);

  const handleCopy = useCallback(() => {
    const input = document.querySelector(
      'input[type="text"]'
    ) as HTMLInputElement | null;
    if (input) {
      input.select();
      document.execCommand("copy");
      addNotification(t("copied-to-clipboard"), "success");
    }
  }, [addNotification, t]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      setTextSaved(false); // Сбрасываем флаг сохранения при изменении инпута
    },
    []
  );
  // Проверка соотвествия введеного текста - что это tradeUrl
  const isValidTradeUrl = (url: string): boolean => {
    const tradeUrlRegex =
      /^https:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=\d+&token=[a-zA-Z0-9_-]+$/;
    return tradeUrlRegex.test(url);
  };

  const handleSave = useCallback(async () => {
    if (!inputValue.trim()) {
      addNotification(t("please-enter-url"), "error");
      return;
    }

    if (!isValidTradeUrl(inputValue)) {
      addNotification(t("please-enter-valid-trade-url"), "error");
      return;
    }

    try {
      const response = await sendTradeUrl(inputValue);
      if (response.success) {
        setTextSaved(true);
        addNotification(t("link-saved-to-database"), "success");
      } else {
        addNotification(t("error-saving-link"), "error");
      }
    } catch (error) {
      console.error(t("error-saving-link"), error);
      addNotification(t("error-saving-link"), "error");
    }
  }, [addNotification, inputValue, t]);

  return (
    <div className="w-full relative flex flex-col bg-dark4 p-4 md:px-6 md:pt-6 md:pb-c22 rounded-14p h-36 md:h-c242">
      <div className="absolute top-0 right-6 md:right-1 w-c105 h-c125 md:w-c136 md:h-c166">
        <Img
          src="/images/profile/zveno.svg"
          alt="trade"
          className="object-cover w-full h-full"
          loader={<span className="inline-block w-full h-full" />}
        />
      </div>
      <div className="flex gap-x-2 items-center">
        <div className="inline-block w-5 h-5 md:w-7 md:h-7 mb-px">
          <Img
            src="/images/profile/trade.svg"
            alt="trade"
            className="object-cover w-full h-full"
            loader={<span className="inline-block w-full h-full mb-px" />}
          />
        </div>
        <p className="text-16 md:text-20 text-white font-extrabold">
          {t("trade-url")}
        </p>
      </div>
      <p className="text-11 md:text-14 text-grey3 font-medium mb-3 md:mb-4">
        {t("your-exchange-link")}
      </p>
      <div className="flex md:grid gap-x-2">
        <label className="w-full relative mb-2.5">
          <input
            type="text"
            placeholder={t("in-trade-url")}
            aria-label="Search cases"
            value={inputValue}
            onChange={handleInputChange}
            className="w-full md:min-w-80 h-10 md:h-c50 pl-c18 pr-9 py-c13 text-12 md:text-13.18 text-label-text bg-label1 hover:bg-h-label1 rounded-30p focus:outline-none focus:bg-f-label1 truncate"
          />
          <button
            type="button"
            onClick={handleCopy}
            disabled={!textSaved || !inputValue.trim()}
            className="flex items-center absolute inset-y-0 right-0 pr-3"
          >
            <div className="inline-block w-5 h-5 md:w-6 md:h-6">
              <Img
                src={"/images/profile/copy.svg"}
                alt="seach"
                className="object-cover w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </div>
          </button>
        </label>
        <button
          onClick={handleSave}
          className="w-c42 max-h-10 md:max-h-none md:w-full flex justify-center items-center md:px-5 md:py-c13 mb-2.5 bg-red4 hover:bg-red5 text-dark3 text-13.20 font-bold rounded-30p z-20"
        >
          <span className="hidden md:block">{t("save-update")}</span>

          <Img
            src="/images/profile/save.svg"
            alt="save"
            width={20}
            height={20}
            loader={
              <span
                style={{
                  width: 20,
                  height: 20,
                }}
                className="md:ml-1.5"
              />
            }
            className="md:ml-1.5"
          />
        </button>
      </div>
      <Link
        to="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="text-12.18 text-center font-medium">
          {t("get-trade-url")}
        </p>
      </Link>
    </div>
  );
});

export default TradeUrl;
