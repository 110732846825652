import { useModalsStore } from "../../zustand/stores/modalsStore";
import QRCode from "react-qr-code";
import { useDepositStore } from "../../zustand/stores/depositStore";
import { ModalWrapper } from "./utils/modalWrapper";

const QrCryptoModal = () => {
  const isQrCryptoModalOpen = useModalsStore(
    (state) => state.isQrCryptoModalOpen
  );
  const setIsQrCryptoModalOpen = useModalsStore(
    (state) => state.setIsQrCryptoModalOpen
  );
  const walletAddress = useDepositStore((state) => state.walletAddress);

  const handleClose = () => setIsQrCryptoModalOpen(false);

  return (
    <ModalWrapper isOpen={isQrCryptoModalOpen} onClose={handleClose}>
      <button
        className="absolute top-2 right-2 text-gray-500"
        onClick={() => setIsQrCryptoModalOpen(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <p className="text-lg font-semibold mb-4 text-blue">QR Code</p>
      {walletAddress ? (
        <QRCode value={walletAddress} size={256} />
      ) : (
        <p>No wallet address available</p>
      )}
    </ModalWrapper>
  );
};

export default QrCryptoModal;
