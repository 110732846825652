import Skeleton from "react-loading-skeleton";

const SkeletonPayItem = () => {
  return (
    <div className="relative w-full aspect-[115/80]">
      <Skeleton
        width="100%"
        height="100%"
        duration={1.5}
        highlightColor="#1c2135"
        baseColor="#282e44"
        className="absolute inset-0"
        style={{
          borderRadius: "14px",
        }}
      />
    </div>
  );
};

export default SkeletonPayItem;
