import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useState } from "react";

interface MenuItem {
  href: string;
  image: string;
  imageHover: string;
  alt: string;
  label: string;
}

interface MenuProps {
  menuItems: MenuItem[];
  onLinkClick?: () => void; // Добавлен необязательный пропс onLinkClick
}

const Menu: React.FC<MenuProps> = ({ menuItems, onLinkClick }) => {
  const { t } = useTranslation();
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  return (
    <nav className="text-sm md:mb-0 mb-c11">
      <ul className="flex md:flex-row flex-col md:gap-7 gap-0.5 items-start md:space-x-4">
        {menuItems.map((item, index) => (
          <li
            key={index}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
            className="md:min-w-max min-w-198 md:bg-transparent bg-dark6 md:px-0 px-2.5 md:py-0 py-2 rounded-lg"
          >
            <Link
              to={item.href}
              className="flex items-center"
              onClick={onLinkClick}
            >
              <Img
                src={hoveredItem === index ? item.imageHover : item.image}
                alt={item.alt}
                width="22"
                height="22"
                loader={
                  <span className="oobject-cover w-c22 h-c22 md:w-[26px] md:h-[26px] md:mr-1.5 mr-2" />
                }
                className="object-cover md:w-[26px] md:h-[26px] md:mr-1.5 mr-2"
              />

              <span
                className={hoveredItem === index ? "text-white" : "text-grey4"}
              >
                {t(item.label)}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export { Menu };
