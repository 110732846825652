import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

interface TradeUrlProps {}

const TelegramBonus: React.FC<TradeUrlProps> = React.memo(() => {
  const { t } = useTranslation();
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };

  return (
    <div className="w-full lg:max-w-482 relative flex justify-between gap-x-7 bg-fon6 pt-4 lg:pt-c21 pb-6 pr-4 lg:pr-6 rounded-2xl overflow-hidden">
      <div className="w-c101 h-32 lg:w-c140 lg:h-c180 -ml-5">
        <Img
          src="/images/profile/tg.svg"
          alt="tg"
          className="object-contain w-full h-full"
          loader={<span className="w-full h-full" />}
        />
      </div>
      <Img
        src="/images/profile/tga.svg"
        alt="tga"
        width={169}
        height={154}
        className="absolute left-0 bottom-0 rounded-2xl hidden lg:block"
      />
      <Img
        src="/images/profile/tga.svg"
        alt="tga"
        width={169}
        height={154}
        className="absolute left-0 bottom-0 rounded-2xl lg:hidden"
      />

      <div className="w-full lg:w-auto">
        <p className="text-14 lg:text-20 text-white font-extrabold mb-0.5 lg:mb-0">
          {t("telegram-bonus")}
        </p>
        <p className="text-11 lg:text-13.18 text-grey4 font-medium mb-2 lg:mb-c14">
          {t("get-a")} <span className="text-yellow4">$0.10</span>{" "}
          {t("bonus-by-completing")}
        </p>
        <button
          className={`w-full lg:min-w-72 flex justify-between items-center max-h-c30 py-1.5 lg:py-c9 pr-1.5 lg:pr-c15 pl-5 mb-c3 bg-blue1 text-${
            isChecked1 ? "c-grey4" : "grey5"
          } text-11 lg:text-13.18 font-medium rounded-42p`}
        >
          {t("connect-telegram")}
          <input
            type="checkbox"
            className="tg-checkbox"
            checked={isChecked1}
            onChange={handleCheckboxChange1}
          />
        </button>
        <button
          className={`w-full lg:min-w-72 flex justify-between items-center max-h-c30 py-1.5 lg:py-c9 pr-1.5 lg:pr-c15 pl-5 mb-2 lg:mb-3 bg-blue1 text-${
            isChecked2 ? "c-grey4" : "grey5"
          } text-11 lg:text-13.18 font-medium rounded-42p`}
        >
          {t("subscribe-telegram")}
          <input
            type="checkbox"
            className="tg-checkbox"
            checked={isChecked2}
            onChange={handleCheckboxChange2}
          />
        </button>
        <button className="w-full lg:min-w-72 flex justify-center items-center max-h-c30 px-5 py-2 lg:py-c13 bg-grey4 hover:bg-grey5 text-dark3 text-12 lg:text-13.20 font-bold rounded-30p">
          {t("check-assignments")}
          <div className="inline-block w-c18 h-c18 lg:w-5 lg:h-5 ml-1.5">
            <Img
              src="/images/profile/save.svg"
              alt="save"
              className="object-cover w-full h-full"
              loader={<span className="inline-block w-full h-full ml-1.5" />}
            />
          </div>
        </button>
      </div>
    </div>
  );
});

export default TelegramBonus;
