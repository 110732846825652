import { useModalsStore } from "../../zustand/stores/modalsStore";
import { ModalWrapper } from "./utils/modalWrapper";

const ErrorModal = () => {
  const isErrorModalOpen = useModalsStore((state) => state.isErrorModalOpen);
  const setIsErrorModalOpen = useModalsStore(
    (state) => state.setIsErrorModalOpen
  );
  const errorMessage = useModalsStore((state) => state.errorMessage);

  const handleClose = () => setIsErrorModalOpen(false);

  return (
    <ModalWrapper isOpen={isErrorModalOpen} onClose={handleClose}>
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        onClick={handleClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <p className="text-lg font-semibold mb-4 text-black">
        Модальное окно с ошибкой
      </p>
      <p className="text-gray-600">{errorMessage}</p>
    </ModalWrapper>
  );
};

export default ErrorModal;
