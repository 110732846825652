import React, { ReactNode } from "react";

import useNoScroll from "./useNoScroll";
import { useOutsideClick } from "./useOutsideClick";

interface ModalWrapperProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string; // Добавляем className как опциональный пропс
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  isOpen,
  onClose,
  children,
  className, // Получаем className из пропсов
}) => {
  const modalRef = useOutsideClick({
    isOpen,
    onClose,
  });

  // Применяем хук useNoScroll
  useNoScroll(isOpen);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        ref={modalRef}
        className={
          className
            ? className
            : "flex flex-col text-center relative bg-dark4 p-8 rounded-3xl py-8 px-c34 mx-2.5 w-full md:w-auto"
        } // Применяем className
      >
        {children}
      </div>
    </div>
  );
};
