import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createSelectors } from "../utils/createSelectors";

interface ModalsStore {
  isReplaceSkinModalOpen: boolean; // Модальное окно замены скина в профиле
  isSellSkinModalOpen: boolean; // Модальное окно продажи одного скина в профиле
  isAllSellSkinModalOpen: boolean; // Модальное окно продажи всех досиупных скинов в профиле
  isErrorModalOpen: boolean; // Модальное окно ошибки (не применяется нигде. так как есть уведомления справа вверху)
  isQrCryptoModalOpen: boolean; // Модальное окно qr-кода в крипто разделе страницы депозита
  errorMessage: string;
  setIsReplaceSkinModalOpen: (actualStatus: boolean) => void;
  setIsSellSkinModalOpen: (actualStatus: boolean) => void;
  setIsAllSellSkinModalOpen: (actualStatus: boolean) => void;
  setIsErrorModalOpen: (actualStatus: boolean) => void;
  setIsQrCryptoModalOpen: (actualStatus: boolean) => void;
  setErrorMessage: (message: string) => void;
}

export const useModalsStore = createSelectors(
  create<ModalsStore>()(
    immer(
      devtools(
        (set) => ({
          isReplaceSkinModalOpen: false,
          isSellSkinModalOpen: false,
          isAllSellSkinModalOpen: false,
          isErrorModalOpen: false,
          isQrCryptoModalOpen: false,
          errorMessage: "",
          skinInfo: null,
          skins: [],
          loading: true,

          allSkinsSold: false,
          setIsReplaceSkinModalOpen: (actualStatus) =>
            set((state) => {
              state.isReplaceSkinModalOpen = actualStatus;
            }),
          setIsSellSkinModalOpen: (actualStatus) =>
            set((state) => {
              state.isSellSkinModalOpen = actualStatus;
            }),
          setIsAllSellSkinModalOpen: (actualStatus) =>
            set((state) => {
              state.isAllSellSkinModalOpen = actualStatus;
            }),
          setIsErrorModalOpen: (actualStatus) =>
            set((state) => {
              state.isErrorModalOpen = actualStatus;
            }),
          setIsQrCryptoModalOpen: (actualStatus) =>
            set((state) => {
              state.isQrCryptoModalOpen = actualStatus;
            }),
          setErrorMessage: (message) =>
            set((state) => {
              state.errorMessage = message;
            }),
        }),
        {
          enabled: true,
          name: "Modals store",
        }
      )
    )
  )
);
