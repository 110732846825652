import React from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

interface DualButtonProps {
  onModeChange: (isFastMode: boolean) => void;
  isFastMode: boolean;
}

const DualButton: React.FC<DualButtonProps> = ({
  onModeChange,
  isFastMode,
}) => {
  const { t } = useTranslation();

  const handleLeftClick = () => {
    onModeChange(false);
  };

  const handleRightClick = () => {
    onModeChange(true);
  };

  const leftButtonClass = `
    w-c74 sm:w-c115 px-4 text-11.24 sm:text-13.24 font-bold py-c13 focus:outline-none transition-colors duration-200 fast-left
    ${isFastMode ? "text-grey2 bg-dark4 hover:bg-dark5" : "text-grey5 bg-dark6"}
    rounded-l-full
  `;

  const rightButtonClass = `
    w-c74 sm:w-c115 px-4 text-11.24 sm:text-13.24 font-bold py-c13 focus:outline-none transition-all duration-200 fast-right
    ${
      isFastMode
        ? "text-dark5 bg-yellow4 shadow-fast"
        : "text-grey2 bg-dark4 hover:bg-dark5"
    }
    rounded-r-full
  `;

  return (
    <div className="border border-dark6 p-c5 rounded-full">
      <div className="relative inline-flex rounded-full">
        <button className={leftButtonClass} onClick={handleLeftClick}>
          {t("classic")}
        </button>

        <div className="absolute min-w-10 min-h-10 w-10 h-10 sm:min-w-16 sm:min-h-16 sm:w-16 sm:h-16 pointer-events-none fast">
          <Img
            src={
              isFastMode
                ? "/images/cases/fast-icon-active.png"
                : "/images/cases/fast-icon.png"
            }
            alt="fast-icon"
            className="object-contain w-full h-full"
            loader={<span className="inline-block w-full h-full" />}
          />
        </div>

        <button className={rightButtonClass} onClick={handleRightClick}>
          {t("fast")}
        </button>
      </div>
    </div>
  );
};

export default DualButton;
