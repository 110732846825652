export const payWallets = [
  {
    image: "/images/deposit/wallets/applepay.svg",
    label: "ApplePay",
  },
  {
    image: "/images/deposit/wallets/fkwallet.svg",
    label: "fkwallet",
  },
  {
    image: "/images/deposit/wallets/qiwi.svg",
    label: "QIWI",
  },
  {
    image: "/images/deposit/wallets/skrill.svg",
    label: "SKRILL",
  },
  {
    image: "/images/deposit/wallets/umoney.svg",
    label: "umoney",
  },
  {
    image: "/images/deposit/wallets/mts.svg",
    label: "MTS",
  },
];
