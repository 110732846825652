import { Outlet } from "react-router";
import { TheHeader } from "../components/header/TheHeader";
import Notification from "../components/Notification";
import { TheFooter } from "../components/footer/TheFooter";

export default function Layout() {
  return (
    <>
      <TheHeader />
      <Outlet />
      <TheFooter />
      <Notification />
    </>
  );
}
