import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserStore } from "../../zustand/stores/userStore";
import { userLogout } from "../../utils/userLogout";

const ProfileBlock = () => {
  const [hoveredItem, setHoveredItem] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const userAvatar = useUserStore.use.userData()?.avatar;
  const balance = useUserStore.use.getBalance()();
  const symbol = useUserStore.use.getSymbol()();
  const { reset, setNeedsRedirect } = useUserStore();
  const isLoggedIn = useUserStore.use.isLoggedIn();

  const handleLogout = async () => {
    userLogout(location.pathname, setNeedsRedirect, reset, navigate);
  };

  const handleProfileClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (isLoggedIn) {
      navigate("/profile");
    } else {
      const steamLoginUrl = `${process.env.REACT_APP_BASE_URL}steam/login`;
      window.location.replace(steamLoginUrl);
    }
  };

  return (
    <div className="flex justify-between items-center md:bg-fon3 md:min-h-c90 min-h-c66 md:p-6 rounded-s-md md:rounded-e-md lg:rounded-e-none transition-transform duration-3000 transform origin-right">
      <div className="flex gap-3 items-center">
        <Link
          to="/profile"
          onClick={handleProfileClick}
          className="md:w-c42 md:h-c42 md:mr-0 mr-1.5"
        >
          <Img
            src={userAvatar || "/images/profile/avatar.svg"}
            alt="profile-avatar"
            width={34}
            height={34}
            className="object-cover md:w-c42 md:h-c42 md:mr-0 mr-1.5 rounded-full"
          />
        </Link>
        <div className="md:block hidden">
          <p className="text-12 text-grey3 font-medium mb-0.5">
            {t("balance")}
          </p>
          <p className="text-15 text-yellow4 font-semibold">
            <span>
              {symbol}
              {balance}
            </span>
          </p>
        </div>
      </div>
      <Link to="/deposit" className="md:block hidden">
        <button className="flex justify-between items-center px-4 py-2.5 ml-5 mr-2.5 bg-yellow4 hover:bg-yellow6 text-dark3 text-sm font-bold rounded-30p">
          {t("deposit")}
          <Img
            src="/images/panel-header/plus.svg"
            alt="login-steam"
            width={20}
            height={20}
            loader={
              <span
                style={{
                  width: 20,
                  height: 20,
                }}
                className="ml-c7"
              />
            }
            className="ml-c7"
          />
        </button>
      </Link>
      <div
        onMouseEnter={() => setHoveredItem(true)}
        onMouseLeave={() => setHoveredItem(false)}
        onClick={handleLogout}
        className="flex justify-center items-center md:w-c42 md:h-c42 w-c34 h-c34 cursor-pointer border border-solid border-dark6 rounded-30p"
      >
        <Img
          src={
            hoveredItem
              ? "/images/panel-header/exit-hover.svg"
              : "/images/panel-header/exit.svg"
          }
          alt="login-steam"
          width={18}
          height={18}
          className="object-cover md:w-5 md:h-5"
        />
      </div>
    </div>
  );
};

export { ProfileBlock };
