import { useState, useMemo, ChangeEvent } from "react";

export const usePaymentLogic = () => {
  const [depositAmount, setDepositAmount] = useState(3000);
  const [getUsdAmount, setGetUsdAmount] = useState(100.86);
  const [isChecked, setIsChecked] = useState(false);
  const [giftCode, setGiftCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const validGiftCodes = useMemo(
    () => ["1", "3", "5", "10", "20", "30", "50", "100"],
    []
  );

  const handleDepositChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setDepositAmount(isNaN(value) ? 0 : value);
  };

  const handleGetUsdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setGetUsdAmount(isNaN(value) ? 0 : value);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleGiftCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGiftCode(event.target.value);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const isGiftCodeValid = useMemo(
    () => validGiftCodes.includes(giftCode),
    [giftCode, validGiftCodes]
  );

  const isGiftCardButtonActive = isChecked && isGiftCodeValid;
  const isWalletsButtonActive = isChecked && phoneNumber.trim() !== "";

  return {
    depositAmount,
    getUsdAmount,
    isChecked,
    giftCode,
    phoneNumber,
    handleDepositChange,
    handleGetUsdChange,
    handleToggle,
    handleGiftCodeChange,
    handlePhoneNumberChange,
    isGiftCardButtonActive,
    isWalletsButtonActive,
  };
};
