export const myProfileItems = [
  {
    id: 0,
    price: 162.76,
    stattrak: true,
    skin_name: "Gungnir-1",
    skin_id: "",
    weapon: "M249",
    rarity: "rare1",
    image_url: "/images/cases/item/1.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 1,
    price: 271.29,
    stattrak: true,
    skin_name: "Gungnir-2",
    skin_id: "",
    weapon: "M249",
    rarity: "rare1",
    image_url: "/images/cases/item/2.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 2,
    price: 211.39,
    stattrak: true,
    skin_name: "Gungnir-3",
    skin_id: "",
    weapon: "M249",
    rarity: "rare1",
    image_url: "/images/cases/item/3.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 3,
    price: 921.29,
    stattrak: true,
    skin_name: "Gungnir-4",
    skin_id: "",
    weapon: "M249",
    rarity: "rare1",
    image_url: "/images/cases/item/4.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 4,
    price: 121.29,
    stattrak: true,
    skin_name: "Gungnir-5",
    skin_id: "",
    weapon: "M249",
    rarity: "rare2",
    image_url: "/images/cases/item/5.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 5,
    price: 7865.29,
    stattrak: true,
    skin_name: "Gungnir-6",
    skin_id: "",
    weapon: "M249",
    rarity: "rare2",
    image_url: "/images/cases/item/6.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 6,
    price: 341.29,
    stattrak: true,
    skin_name: "Gungnir-7",
    skin_id: "",
    weapon: "M249",
    rarity: "rare2",
    image_url: "/images/cases/item/7.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 7,
    price: 142.71,
    stattrak: true,
    skin_name: "Gungnir-8",
    skin_id: "",
    weapon: "M249",
    rarity: "rare2",
    image_url: "/images/cases/item/8.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 8,
    price: 116.77,
    stattrak: true,
    skin_name: "Gungnir-9",
    skin_id: "",
    weapon: "M249",
    rarity: "rare3",
    image_url: "/images/cases/item/9.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 9,
    price: 115.67,
    stattrak: true,
    skin_name: "Gungnir-10",
    skin_id: "",
    weapon: "M249",
    rarity: "rare3",
    image_url: "/images/cases/item/10.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 10,
    price: 108.85,
    stattrak: true,
    skin_name: "Gungnir-11",
    skin_id: "",
    weapon: "M249",
    rarity: "rare3",
    image_url: "/images/cases/item/11.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 11,
    price: 175.29,
    stattrak: true,
    skin_name: "Gungnir-12",
    skin_id: "",
    weapon: "M249",
    rarity: "rare3",
    image_url: "/images/cases/item/12.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 12,
    price: 330.29,
    stattrak: true,
    skin_name: "Gungnir-13",
    skin_id: "",
    weapon: "M249",
    rarity: "rare4",
    image_url: "/images/cases/item/13.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 13,
    price: 150.58,
    stattrak: true,
    skin_name: "Gungnir-14",
    skin_id: "",
    weapon: "M249",
    rarity: "rare4",
    image_url: "/images/cases/item/14.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 14,
    price: 304.97,
    stattrak: true,
    skin_name: "Gungnir-15",
    skin_id: "",
    weapon: "M249",
    rarity: "rare4",
    image_url: "/images/cases/item/15.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 15,
    price: 275.14,
    stattrak: true,
    skin_name: "Gungnir-16",
    skin_id: "",
    weapon: "M249",
    rarity: "rare4",
    image_url: "/images/cases/item/16.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 16,
    price: 113.44,
    stattrak: true,
    skin_name: "Gungnir-17",
    skin_id: "",
    weapon: "M249",
    rarity: "rare5",
    image_url: "/images/cases/item/17.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 17,
    price: 256.77,
    stattrak: true,
    skin_name: "Gungnir-18",
    skin_id: "",
    weapon: "M249",
    rarity: "rare5",
    image_url: "/images/cases/item/18.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 18,
    price: 115.69,
    stattrak: true,
    skin_name: "Gungnir-19",
    skin_id: "",
    weapon: "M249",
    rarity: "rare5",
    image_url: "/images/cases/item/19.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 19,
    price: 198.57,
    stattrak: true,
    skin_name: "Gungnir-20",
    skin_id: "",
    weapon: "M249",
    rarity: "rare5",
    image_url: "/images/cases/item/20.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 20,
    price: 139.29,
    stattrak: true,
    skin_name: "Gungnir-21",
    skin_id: "",
    weapon: "M249",
    rarity: "rare6",
    image_url: "/images/cases/item/21.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 21,
    price: 178.64,
    stattrak: true,
    skin_name: "Gungnir-22",
    skin_id: "",
    weapon: "M249",
    rarity: "rare6",
    image_url: "/images/cases/item/22.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 22,
    price: 132.74,
    stattrak: true,
    skin_name: "Gungnir-23",
    skin_id: "",
    weapon: "M249",
    rarity: "rare6",
    image_url: "/images/cases/item/23.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 23,
    price: 936.82,
    stattrak: true,
    skin_name: "Gungnir-24",
    skin_id: "",
    weapon: "M249",
    rarity: "rare6",
    image_url: "/images/cases/item/24.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 24,
    price: 999.11,
    stattrak: true,
    skin_name: "Gungnir-25",
    skin_id: "",
    weapon: "M249",
    rarity: "rare7",
    image_url: "/images/cases/item/25.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 25,
    price: 111.77,
    stattrak: true,
    skin_name: "Gungnir-26",
    skin_id: "",
    weapon: "M249",
    rarity: "rare7",
    image_url: "/images/cases/item/26.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 26,
    price: 245.32,
    stattrak: true,
    skin_name: "Gungnir-27",
    skin_id: "",
    weapon: "M249",
    rarity: "rare7",
    image_url: "/images/cases/item/27.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
  {
    id: 27,
    price: 387.28,
    stattrak: true,
    skin_name: "Gungnir-28",
    skin_id: "",
    weapon: "M249",
    rarity: "rare7",
    image_url: "/images/cases/item/28.png",
    status: "SOLD",
    wear_en: "WW",
    name_id: "Case Name",
  },
];
