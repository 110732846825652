import React, { useState, useEffect, useMemo } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import { Currency } from "./Currency";
import Pay from "./Pay";
import { payCards } from "../../utils/data/pay-cards";
import { payWallets } from "../../utils/data/pay-wallets";
import { payCrypto } from "../../utils/data/pay-crypto";
import PayText from "./PayText";
import { PayItem } from "./payItem/PayItem";
import SkeletonPayItem from "./payItem/SkeletonPayItem";
import { PayItemGift } from "./payItem/PayItemGift";
import { payGift } from "../../utils/data/pay-gift";
import { paySkins } from "../../utils/data/pay-skins";

import {
  AllPayItemTypes,
  PayItemGiftType,
  PayItemSkinsType,
  PayItemType,
} from "./types";
import { PayItemSkin } from "./payItem/PayItemSkin";

const Deposits: React.FC = React.memo(() => {
  const [activeButton, setActiveButton] = useState<string>("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [selectedPayItem, setSelectedPayItem] = useState<
    PayItemType | PayItemSkinsType | null
  >(null);
  const { t } = useTranslation();

  const payMethods = [
    {
      value: "All",
      icon: "/images/deposit/all.svg",
      hoverIcon: "/images/deposit/all-hover.svg",
      label: "all",
    },
    {
      value: "Cards",
      icon: "/images/deposit/card.svg",
      hoverIcon: "/images/deposit/card-hover.svg",
      label: "cards",
    },
    {
      value: "Wallets",
      icon: "/images/deposit/wallet.svg",
      hoverIcon: "/images/deposit/wallet-hover.svg",
      label: "wallets",
    },
    {
      value: "Crypto",
      icon: "/images/deposit/crypto.svg",
      hoverIcon: "/images/deposit/crypto-hover.svg",
      label: "crypto",
    },
    {
      value: "Skins",
      icon: "/images/deposit/skins.svg",
      hoverIcon: "/images/deposit/skins-hover.svg",
      label: "skins",
    },
    {
      value: "Gift Card",
      icon: "/images/deposit/gift-card.svg",
      hoverIcon: "/images/deposit/gift-card-hover.svg",
      label: "gift-card",
    },
  ];

  const allPayItems: AllPayItemTypes[] = useMemo(
    () => [
      ...payCards.map((item) => ({ ...item, category: "Cards" })),
      ...payWallets.map((item) => ({ ...item, category: "Wallets" })),
      ...payCrypto.map((item) => ({ ...item, category: "Crypto" })),
      ...paySkins.map((item) => ({ ...item, category: "Skins" })),
      ...payGift.map((item) => ({ ...item, category: "Gift Card" })),
    ],
    []
  );

  const selectFirstCard = () => {
    const firstCard = allPayItems.find(
      (item) => item.category === "Cards"
    ) as PayItemType;
    if (firstCard) {
      setSelectedPayItem(firstCard);
      setSelectedCategory("Cards");
    }
  };

  useEffect(() => {
    if (activeButton === "All" && !selectedPayItem) {
      selectFirstCard();
    }
  }, [activeButton, allPayItems]);

  const groupedPayItems = useMemo(() => {
    const groups: { [key: string]: AllPayItemTypes[] } = {
      Cards: [],
      Wallets: [],
      Crypto: [],
      Skins: [],
      "Gift Card": [],
    };

    allPayItems.forEach((item) => {
      if (groups[item.category]) {
        groups[item.category].push(item);
      }
    });

    return groups;
  }, [allPayItems]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [activeButton]);

  const handlePayItemSelect = (item: PayItemType | PayItemSkinsType) => {
    setSelectedPayItem(selectedPayItem === item ? null : item);
    setSelectedCategory(item.category);
  };

  const filteredPayItems = useMemo(() => {
    if (activeButton === "All") {
      return allPayItems.filter((item) => item.category !== "Gift Card");
    } else {
      return allPayItems.filter((item) => item.category === activeButton);
    }
  }, [activeButton, allPayItems]);

  const renderPayItem = (item: AllPayItemTypes, index: number) => {
    if (item.category === "Gift Card") {
      return <PayItemGift key={index} payItem={item as PayItemGiftType} />;
    } else if (item.category === "Skins") {
      return (
        <PayItemSkin
          key={index}
          payItem={item as PayItemSkinsType}
          isSelected={selectedPayItem === item}
          onSelect={(selectedItem) => handlePayItemSelect(selectedItem)}
        />
      );
    } else {
      return (
        <PayItem
          key={index}
          payItem={item as PayItemType}
          isSelected={selectedPayItem === item}
          onSelect={handlePayItemSelect}
        />
      );
    }
  };

  const renderPayItems = () => {
    if (loading) {
      return Array(filteredPayItems.length)
        .fill(0)
        .map((_, index) => <SkeletonPayItem key={index} />);
    }

    if (activeButton === "All") {
      return Object.entries(groupedPayItems).map(([category, items]) => {
        if (category === "Gift Card" || items.length === 0) return null;

        return (
          <React.Fragment key={category}>
            <h3 className="col-span-full text-18 font-semibold text-white mt-4 mb-2">
              {t(category.toLowerCase())}
            </h3>
            {items.map((item, index) => renderPayItem(item, index))}
          </React.Fragment>
        );
      });
    }

    return filteredPayItems.map((item, index) => renderPayItem(item, index));
  };

  const handleCheckboxChange = (value: string) => {
    if (value !== activeButton) {
      setActiveButton(value);
      setIsDropdownOpen(false);
      setLoading(true);
      if (value === "All") {
        selectFirstCard();
      } else {
        setSelectedPayItem(null);
        setSelectedCategory(value);
      }
    }
  };

  const renderButtonWithCheckbox = (
    value: string,
    iconSrc: string,
    hoverIconSrc: string,
    label: string
  ) => (
    <div
      className={`flex items-center md:text-12 lg:text-14.16 font-medium p-3 rounded-lg md:rounded-30p cursor-pointer mb-0.5 md:mb-0 ${
        activeButton === value
          ? "bg-dark6 opacity-50 md:opacity-100 text-blue md:text-grey5"
          : "md:border-solid md:border-dark5 bg-dark6 text-blue md:text-grey3 z-20 md:border-[1px]"
      }`}
      onClick={() => handleCheckboxChange(value)}
    >
      <Img
        src={activeButton === value ? hoverIconSrc : iconSrc}
        alt={label}
        width={20}
        height={20}
        loader={<span style={{ width: 20, height: 20 }} className="mr-1.5" />}
        className="mr-1.5"
      />
      {t(label)}
      <input
        type="checkbox"
        checked={activeButton === value}
        onChange={() => handleCheckboxChange(value)}
        className="m-checkbox hidden md:block"
      />
    </div>
  );

  return (
    <>
      <section className="w-full lg:min-w-main flex md:flex-col lg:flex-row justify-between items-center gap-y-2 md:bg-dark4 rounded-2xl md:px-1 md:py-4 lg:px-5 lg:py-c22 zoom-dep">
        <div className="flex md:flex-col lg:flex-row gap-x-5 gap-y-2 items-center w-full lg:w-auto">
          <div className="items-center hidden md:flex">
            <Img
              src="/images/deposit/deposit-logo.svg"
              alt="deposit-logo"
              width={28}
              height={28}
              loader={
                <span style={{ width: 28, height: 28 }} className="mr-1.5" />
              }
              className="mr-1.5"
            />
            <span className="text-18 text-grey4 font-semibold">
              {t("pay-method")}
            </span>
          </div>

          <div className="relative w-full md:w-auto">
            <div
              className="flex items-center w-c180 h-c52 justify-between md:hidden p-3 bg-gradient-grey rounded-xl md:rounded-30p cursor-pointer mob-menu"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="flex items-center">
                <Img
                  src={
                    payMethods.find((method) => method.value === activeButton)
                      ?.icon || ""
                  }
                  alt={activeButton}
                  width={20}
                  height={20}
                  loader={
                    <span
                      style={{ width: 20, height: 20 }}
                      className="mr-1.5"
                    />
                  }
                  className="mr-1.5"
                />
                <span className="text-14.16 font-medium">
                  {t(
                    payMethods.find((method) => method.value === activeButton)
                      ?.label || ""
                  )}
                </span>
              </div>
              <span
                className={`transform transition-transform ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
              >
                <Img
                  src="/images/deposit/st.svg"
                  alt="exchange"
                  width={16}
                  height={16}
                  loader={
                    <span
                      style={{
                        width: 16,
                        height: 16,
                      }}
                    />
                  }
                />
              </span>
            </div>
            <div
              className={`absolute left-0 right-0 mt-2 z-10 bg-dark5 w-c180 md:w-full p-c14 shadow-custom md:shadow-none md:p-0 rounded-lg md:static md:border-none md:bg-transparent md:flex md:items-center md:gap-2 mob-menu ${
                isDropdownOpen ? "block" : "hidden md:flex"
              }`}
            >
              {payMethods.map((method) => (
                <div key={method.value} className="md:inline-block">
                  {renderButtonWithCheckbox(
                    method.value,
                    method.icon,
                    method.hoverIcon,
                    method.label
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex gap-1 md:gap-5 items-center text-12.18 font-semibold md:text-14.18">
          {t("currency")} <Currency />
        </div>
      </section>
      <section className="w-full flex flex-col md:flex-row gap-2.5 md:gap-5 mt-2.5 md:mt-4">
        <div className="w-full flex flex-wrap justify-center items-start">
          <div className="w-full grid grid-cols-3 lg:grid-cols-5 gap-1.5 sm:gap-c5">
            {renderPayItems()}
          </div>
          <PayText />
        </div>
        <Pay
          activeButton={selectedCategory}
          selectedPayItem={selectedPayItem}
        />
      </section>
    </>
  );
});

export default Deposits;
