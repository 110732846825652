import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import React from "react";
import { UserType } from "../../utils/types";
import BalanceSection from "./BalanceSection";

interface StatProfileProps {
  userData: UserType | null;
}

const StatProfile: React.FC<StatProfileProps> = React.memo(({ userData }) => {
  const { t } = useTranslation();

  return (
    <div className="lg:max-w-314 flex lg:flex-col gap-3 lg:h-c242">
      <div className="hidden md:block">
        <BalanceSection />
      </div>
      <div className="w-full flex justify-between gap-x-px mm-stat">
        <div className="w-full flex flex-col bg-fon5 items-center text-center py-c17.5 px-6 md:min-w-0 lg:max-w-105 md:py-c23 md:px-c17.5 rounded-s-14p">
          <Img
            src="/images/profile/case.svg"
            alt="case"
            width={28}
            height={28}
            loader={
              <span
                style={{
                  width: 28,
                  height: 28,
                }}
                className="mb-2.5"
              />
            }
            className="mb-2.5"
          />
          <p className="text-12 md:text-15 text-grey5 font-bold mb-0.5">
            {userData?.cases_count ?? 0}
          </p>
          <p className="max-w-70 text-9.12 md:text-10.13 text-grey2 font-medium uppercase">
            {t("case-opened")}
          </p>
        </div>
        <div className="w-full flex flex-col bg-fon5 items-center text-center py-c17.5 px-6 md:min-w-0 lg:max-w-105 md:py-c23 md:px-c17.5">
          <Img
            src="/images/profile/withdraw.svg"
            alt="withdraw"
            width={28}
            height={28}
            loader={
              <span
                style={{
                  width: 28,
                  height: 28,
                }}
                className="mb-c7 md:mb-2.5"
              />
            }
            className="mb-c7 md:mb-2.5"
          />
          <p className="text-12 md:text-15 text-grey5 font-bold mb-0.5">
            {userData?.skins_count_steam ?? 0}
          </p>
          <p className="max-w-70 text-9.12 md:text-10.13 text-grey2 font-medium uppercase">
            {t("withdraw-to-steam")}
          </p>
        </div>
        <div className="w-full flex flex-col bg-fon5 items-center text-center py-c17.5 px-6 md:min-w-0 lg:max-w-105 md:py-c23 md:px-c17.5 rounded-e-14p">
          <Img
            src="/images/profile/skins.svg"
            alt="skins"
            width={28}
            height={28}
            loader={
              <span
                style={{
                  width: 28,
                  height: 28,
                }}
                className="mb-2.5"
              />
            }
            className="mb-2.5"
          />
          <p className="text-12 md:text-15 text-grey5 font-bold mb-0.5">
            {userData?.skins_count ?? 0}
          </p>
          <p className="max-w-70 text-9.12 md:text-10.13 text-grey2 font-medium uppercase">
            {t("all-skins")}
          </p>
        </div>
      </div>
    </div>
  );
});

export default StatProfile;
