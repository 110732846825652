export const menuItems = [
  {
    href: "/giveaways",
    image: "/images/panel-header/gift.svg",
    imageHover: "/images/panel-header/gift-white.svg",
    alt: "panel-logo",
    label: "giveaways",
  },
  {
    href: "/upgrade",
    image: "/images/panel-header/upgrade.svg",
    imageHover: "/images/panel-header/upgrade-white.svg",
    alt: "upgrade",
    label: "upgrade",
  },
  {
    href: "/contracts",
    image: "/images/panel-header/contracts.svg",
    imageHover: "/images/panel-header/contracts-white.svg",
    alt: "contracts",
    label: "contracts",
  },
];
