import { Img } from "react-image";
import { Link } from "react-router-dom";
import { useUserStore } from "../../zustand/stores/userStore";

const DepositButton = () => {
  const balance = useUserStore.use.getBalance()();
  return (
    <Link to="/deposit">
      <button className="flex justify-between items-center gap-1.5 pl-2.5 pr-2 py-c7 ml-5 mr-2.5 bg-yellow4 hover:bg-yellow6 text-dark3 text-12.18 font-bold rounded-30p">
        {balance ?? 0}
        <Img
          src="/images/panel-header/plus.svg"
          alt="login-steam"
          width={20}
          height={20}
        />
      </button>
    </Link>
  );
};

export { DepositButton };
