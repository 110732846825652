import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import GiftCardPay from "./PaymentMethods/GiftCardPay";
import CryptoPay from "./PaymentMethods/CryptoPay";
import WalletsPay from "./PaymentMethods/WalletsPay";

interface PaymentFormProps {
  activeButton: string;
  depositAmount: number;
  getUsdAmount: number;
  giftCode: string;
  phoneNumber: string;
  handleDepositChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleGetUsdChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleGiftCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  activeButton,
  depositAmount,
  getUsdAmount,
  giftCode,
  phoneNumber,
  handleDepositChange,
  handleGetUsdChange,
  handleGiftCodeChange,
  handlePhoneNumberChange,
}) => {
  const { t } = useTranslation();

  const renderPayComponent = () => {
    switch (activeButton) {
      case "Wallets":
        return (
          <WalletsPay
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
        );
      case "Crypto":
        return <CryptoPay />;
      case "Gift Card":
        return (
          <GiftCardPay
            giftCode={giftCode}
            onGiftCodeChange={handleGiftCodeChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col w-full gap-y-4 items-start bg-dark4 rounded-14p p-c18 mb-2.5 md:mb-0 md:mt-2.5 order-1 md:order-3">
      {activeButton !== "Gift Card" && (
        <div className="flex justify-between items-center gap-c7">
          {/* Deposit amount input */}
          <div className="flex gap-1.5 items-center w-full py-2 px-4 md:py-c13 text-13.18 text-label-text bg-label1 rounded-30p">
            <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
              <Img
                src="/images/deposit/swap-left.svg"
                alt="swap-left"
                className="object-contain w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-11.12 text-grey3">Sum RUB</span>
              <input
                type="number"
                value={depositAmount}
                onChange={handleDepositChange}
                className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-white"
              />
            </div>
          </div>
          {/* Exchange icon */}
          <div className="flex-shrink-0 w-c22 h-c22 sm:w-c30 sm:h-c30">
            <Img
              src="/images/deposit/exchange.svg"
              alt="exchange"
              className="object-contain w-full h-full"
              loader={<span className="inline-block w-full h-full" />}
            />
          </div>
          {/* USD amount input */}
          <div className="flex gap-1.5 w-full py-2 px-4 md:py-c13 text-13.18 text-label-text bg-label1 rounded-30p">
            <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
              <Img
                src="/images/deposit/swap-right.svg"
                alt="swap-right"
                className="object-contain w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-11.12 text-grey3">Get USD</span>
              <input
                type="number"
                value={getUsdAmount}
                onChange={handleGetUsdChange}
                className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-white"
              />
            </div>
          </div>
        </div>
      )}
      {renderPayComponent()}
      <div className="w-full bg-c-grey2 py-2.5 px-4 rounded-19p border border-dotted border-c-grey3">
        <p className="text-13">
          {t("minimum-amount")} <span className="text-white">1 USD</span>
        </p>
      </div>
    </div>
  );
};

export default PaymentForm;
