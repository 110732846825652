import React, { useEffect, useRef, useState } from "react";
import { SkinTypeSlider } from "../../../utils/types";
import { getSliderItemfon } from "../../../utils/rarityFonColor";

interface SliderHorizontalProps {
  skins: SkinTypeSlider[];
  initialSpin?: string;
  onSpinEnd: () => void;
  isFastMode: boolean;
}

const SliderHorizontal: React.FC<SliderHorizontalProps> = ({
  skins,
  initialSpin,
  onSpinEnd,
  isFastMode,
}) => {
  const wheelRef = useRef<HTMLDivElement>(null);
  const [spinning, setSpinning] = useState(false);
  const [winningCardData, setWinningCardData] = useState<SkinTypeSlider | null>(
    null
  );
  const animationRef = useRef<Animation | null>(null);
  const centeringAnimationRef = useRef<Animation | null>(null);
  const [showWinningCard, setShowWinningCard] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);

  const cardWidth = 210;
  const cardHeight = 280;
  const ANIMATION_DURATION = 6000;
  const CENTERING_DURATION = 500;

  useEffect(() => {
    if (initialSpin !== undefined) {
      if (isFastMode) {
        const winningCard = skins.find((skin) => skin.id === initialSpin);

        if (winningCard) {
          setWinningCardData(winningCard);
          setShowWinningCard(true);
          setAnimationComplete(true);
          onSpinEnd();
        }
      } else {
        spinWheelToId(initialSpin);
      }
    }
  }, [initialSpin, isFastMode, skins]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (animationRef.current && !animationComplete) {
        if (document.hidden) {
          animationRef.current.pause();
          if (centeringAnimationRef.current) {
            centeringAnimationRef.current.pause();
          }
        } else {
          animationRef.current.play();
          if (centeringAnimationRef.current) {
            centeringAnimationRef.current.play();
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [animationComplete]);

  const spinWheelToId = (id: string) => {
    if (!wheelRef.current) return;
    setSpinning(true);
    setShowWinningCard(false);
    setAnimationComplete(false);

    const wheel = wheelRef.current;
    const targetIndex = skins.findIndex((skin) => skin.id === id);
    if (targetIndex === -1) return;

    const rows = 12;
    const initialOffset = -(cardWidth / 2);
    let landingPosition =
      (rows * skins.length + targetIndex) * cardWidth - initialOffset;
    const randomize = Math.floor(Math.random() * cardWidth) - cardWidth / 2;
    landingPosition += randomize;

    const keyframes = [
      { transform: "translateX(0)" },
      { transform: `translateX(-${landingPosition}px)` },
    ];

    const timing = {
      duration: ANIMATION_DURATION,
      easing: "cubic-bezier(.1,.7,.5,1)",
    };

    if (animationRef.current) {
      animationRef.current.cancel();
    }

    animationRef.current = wheel.animate(keyframes, timing);

    animationRef.current.onfinish = () => {
      wheel.style.transform = `translateX(-${landingPosition}px)`;

      const centeringDistance = randomize;
      const centeringKeyframes = [
        { transform: `translateX(-${landingPosition}px)` },
        { transform: `translateX(-${landingPosition - centeringDistance}px)` },
      ];

      const centeringTiming = {
        duration: CENTERING_DURATION,
        easing: "ease-out",
      };

      centeringAnimationRef.current = wheel.animate(
        centeringKeyframes,
        centeringTiming
      );

      centeringAnimationRef.current.onfinish = () => {
        setSpinning(false);
        wheel.style.transform = `translateX(-${
          landingPosition - centeringDistance
        }px)`;
        setWinningCardData(skins[targetIndex]);
        setShowWinningCard(true);
        setAnimationComplete(true);
        onSpinEnd();
      };
    };
  };

  const renderSkins = () => {
    const repeatedSkins = [...Array(skins.length * 2 + 30)].flatMap(
      () => skins
    );

    return repeatedSkins.map((skin, index) => (
      <div
        key={`${skin.id}-${index}`}
        data-id={skin.id}
        className={`card relative ${!spinning ? "dimmed-card" : ""}`}
        style={{ width: `${cardWidth}px`, flexShrink: 0 }}
      >
        {getSliderItemfon(skin.rarity)}
        <img
          style={{ zIndex: 2, marginTop: "45px", position: "relative" }}
          src={skin.image_url}
          alt={skin.skin_name}
          className="gor-img rounded mb-[5px]"
        />
        <p className="text-14 text-cases-name font-medium relative z-2">
          {skin.weapon}
        </p>
        <p className="w-48 text-16 text-cust-white font-medium text-center relative z-2 truncate">
          {skin.skin_name}
        </p>
      </div>
    ));
  };

  return (
    <div className="relative">
      <div
        className={`roulette-wrapper relative flex justify-center w-full m-0 overflow-hidden rounded-20p ${
          isFastMode ? "opacity-50" : ""
        }`}
      >
        <div
          className="selector w-[2px] bg-red8 left-1/2 h-[90%] top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-10"
          style={{ display: spinning ? "block" : "none" }}
        ></div>
        <div ref={wheelRef} className="wheel flex">
          {renderSkins()}
        </div>
      </div>
      {showWinningCard && winningCardData && (
        <div
          className="absolute"
          style={{
            width: `${cardWidth}px`,
            height: `${cardHeight}px`,
            zIndex: 30,
            top: `calc(50% - ${cardHeight / 2}px)`,
            left: `calc(50% - ${cardWidth / 2}px)`,
          }}
        >
          <div className="card relative enlarged-card">
            {getSliderItemfon(winningCardData.rarity)}
            <img
              style={{ zIndex: 2, marginTop: "45px", position: "relative" }}
              src={winningCardData.image_url}
              alt={winningCardData.skin_name}
              className="gor-img rounded mb-[5px]"
            />
            <p className="text-14 text-cases-name font-medium relative z-2">
              {winningCardData.weapon}
            </p>
            <p className="text-16 text-cust-white font-medium text-center relative px-0.5 z-2">
              {winningCardData.skin_name}
            </p>
          </div>
        </div>
      )}
      <div className="arrow-up"></div>
      <div className="arrow-down"></div>
    </div>
  );
};

export default SliderHorizontal;
