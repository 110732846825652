export const payCrypto = [
  {
    image: "/images/deposit/crypto/bitcoin.svg",
    label: "bitcoin",
  },
  {
    image: "/images/deposit/crypto/tether.svg",
    label: "trc-20",
  },
  {
    image: "/images/deposit/crypto/usd.svg",
    label: "usd",
  },
  {
    image: "/images/deposit/crypto/ton.svg",
    label: "ton",
  },
];
