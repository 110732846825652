import { useState, useEffect } from "react";
import { Range, getTrackBackground } from "react-range";

interface RangeSliderProps {
  onChange: (values: [number, number]) => void; // Обработчик для передачи диапазона цен
  resetRange: boolean; // Проп для сброса диапазона цен
}

const RangeSlider = ({ onChange, resetRange }: RangeSliderProps) => {
  const [values, setValues] = useState<[number, number]>([0, 40000]);

  const colors = ["#282E44", "#ffc85f", "#282E44"];

  useEffect(() => {
    if (resetRange) {
      setValues([0, 40000]); // Сбрасываем диапазон цен до начальных значений
    }
  }, [resetRange]);

  return (
    <section className="flex flex-col gap-2 w-full h-full max-w-md items-start justify-center sm:w-full md:w-c242 md:mb-1">
      <span className="w-full flex justify-between text-12.18 font-semibold text-grey5 mb-1">
        <div>${values[0]}</div>
        <div>${values[1]}</div>
      </span>
      <div className="relative w-full">
        <Range
          values={values}
          step={100}
          min={0}
          max={50000}
          onChange={(newValues) => {
            // Преобразуем newValues в кортеж [number, number]
            const newRange: [number, number] = [newValues[0], newValues[1]];
            setValues(newRange);
            onChange(newRange); // Вызов обработчика при изменении значений
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="h-1.5 rounded-xl"
              style={{
                ...props.style,
                background: getTrackBackground({
                  values: values,
                  colors: colors,
                  min: 0,
                  max: 50000,
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              key={props.key}
              className="w-2.5 h-2.5 rounded-full bg-cwhite cursor-pointer focus:outline-none"
              style={{ ...props.style }}
            />
          )}
        />
      </div>
    </section>
  );
};

export default RangeSlider;
