import "./deposit.css";
import Deposits from "../../components/deposit/Deposits";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import QrCryptoModal from "../../components/modals/QrCryptoModal";

export default function Deposit() {
  const isQrCryptoModalOpen = useModalsStore(
    (state) => state.isQrCryptoModalOpen
  );

  return (
    <main className="flex flex-col items-center max-w-main mx-auto pt-4 md:pt-c30 pb-c66 main-mx">
      <h1 className="text-14 md:text-24 font-medium md:font-normal text-white mb-3 md:mb-4 text-left w-full">
        Deposit
      </h1>
      <Deposits />
      {isQrCryptoModalOpen && <QrCryptoModal />}
    </main>
  );
}
