import Skeleton from "react-loading-skeleton";

const SkeletonCardHeader = () => {
  return (
    <div className="md:mr-c5 mr-0.5 w-20 md:w-c140 rounded-14p">
      <Skeleton
        width={140}
        height={109}
        duration={1.5}
        highlightColor="#1c2135"
        baseColor="#282e44"
        className="rounded-14p"
      />
    </div>
  );
};

export default SkeletonCardHeader;
