import api from "./api";

interface ChangeCurrencyResponse {
  success: boolean;
  message?: string;
}

export async function changeCurrency(currency: "USD" | "RUB"): Promise<ChangeCurrencyResponse> {
  return api.request<ChangeCurrencyResponse>(
    "user/currencySelection",
    {
      method: "POST",
      data: {
        currency: currency,
      },
    }
  );
}