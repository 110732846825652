import { useState } from "react";
import { Img } from "react-image";

const Sound = () => {
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const toggleSound = () => {
    setIsSoundOn(!isSoundOn);
  };

  return (
    <div>
      <Img
        src={
          isSoundOn
            ? hoveredItem === "sound"
              ? "/images/panel-header/sound-on-hover.svg"
              : "/images/panel-header/sound-on.svg"
            : hoveredItem === "sound"
            ? "/images/panel-header/sound-off-hover.svg"
            : "/images/panel-header/sound-off.svg"
        }
        alt="sound"
        width={20}
        height={20}
        className="cursor-pointer"
        onClick={toggleSound}
        onMouseEnter={() => setHoveredItem("sound")}
        onMouseLeave={() => setHoveredItem(null)}
      />
    </div>
  );
};

export { Sound };
