import React from "react";
import { useTranslation } from "react-i18next";
import SwitchBlock from "../../filtr/SwitchBlock";
import { AgreementBlockProps } from "../types";

const AgreementBlock: React.FC<AgreementBlockProps> = ({
  isChecked,
  handleToggle,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-start mt-c18 order-4">
      <SwitchBlock isChecked={isChecked} handleToggle={handleToggle} />
      <p className="text-13.20 ml-1">
        {t("i-accept")}
        <span className="text-white"> {t("user-agreement")}</span>
      </p>
    </div>
  );
};

export default AgreementBlock;
