import { ProfileSkinsResponse, SkinType } from "../../utils/types";
import api from "../api";

export async function getSkinsForProfile(
  page: number,
  isDefault: boolean
): Promise<{
  skins: SkinType[];
  skin_count: number;
  hasMore: boolean;
}> {
  try {
    const response = await api.request<ProfileSkinsResponse>("user/skins", {
      params: {
        DEFAULT: isDefault,
        page: page,
      },
    });

    if (response.success && Array.isArray(response.data)) {
      return {
        skins: response.data,
        skin_count: response.skin_count,
        hasMore: response.hasMore,
      };
    } else {
      return { skins: [], hasMore: false, skin_count: 0 };
    }
  } catch (error) {
    throw error;
  }
}
