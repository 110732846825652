import { useState } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";

const Giveaways = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [hoveredButton, setHoveredButton] = useState<string | null>(null);
  const { t } = useTranslation();

  return (
    <section
      className={`w-full md:h-c90 h-c69 flex justify-end items-center bg-fon4 rounded-2xl p-5 relative cursor-pointer ${
        hoveredItem === "hover" ? "gap-3" : "gap-c21"
      } transition-all duration-300`}
      onMouseEnter={() => setHoveredItem("hover")}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <div
        className={`absolute bottom-0 left-0 transition-all duration-300 ${
          hoveredItem
            ? "w-[98px] h-[96px] md:w-[112px] md:h-[110px]"
            : "w-[77px] h-[75px] md:w-[106px] md:h-[104px]"
        }`}
      >
        <img
          src={`/images/giveaways/giveaways${hoveredItem ? "-big" : ""}.png`}
          alt="giveaways"
          className="object-cover"
        />
      </div>
      <div className="md:relative absolute md:left-0 left-24">
        <p className="md:text-16 text-14 text-white font-bold">
          {t("giveaways")}
        </p>
        <p className="md:text-14 text-12 text-yellow4 font-bold">
          {t("up-to")} $5 464.40
        </p>
      </div>
      <button
        className={`h-c34 flex justify-center items-center px-3 py-2 rounded-30p ${
          hoveredItem === "hover" ? "w-c51 bg-yellow5" : "w-c42 bg-yellow4"
        } ${
          hoveredButton === "button" ? "bg-yellow6" : "bg-yellow4"
        } transition-all duration-300`}
        onMouseEnter={() => setHoveredButton("button")}
        onMouseLeave={() => setHoveredButton(null)}
      >
        <Img
          src="/images/giveaways/arrow-right.svg"
          alt="arrow-right"
          width={18}
          height={18}
        />
      </button>
    </section>
  );
};

export { Giveaways };
