import { Img } from "react-image";

export const getBgColorClass = (rarity: string): string => {
  switch (rarity) {
    case "Extraordinary":
    case "Master":
    case "Covert":
    case "экстраординарного типа":
    case "Мастерский":
    case "Тайное":
      return "bg-rare1"; // красный
    case "Remarkable":
    case "Exceptional":
    case "Restricted":
    case "примечательного типа":
    case "Исключительный":
    case "Запрещённое":
      return "bg-rare2"; // фиолетовый
    case "High Grade":
    case "Distinguished":
    case "Mil-Spec Grade":
    case "высшего класса":
    case "Заслуженный":
    case "Армейское качество":
      return "bg-rare3"; // Синий
    case "Industrial Grade":
    case "Промышленное качество":
      return "bg-rare4"; // Голубой
    case "Exotic":
    case "Superior":
    case "Classified":
    case "экзотичного вида":
    case "Превосходный":
    case "Засекреченное":
      return "bg-rare5"; // Пурпурный
    case "Base Grade":
    case "Consumer Grade":
    case "Default":
    case "базового класса":
    case "Ширпотреб":
    case "Стандартное":
      return "bg-rare6"; // Серый
    case "Contraband":
    case "Контрабанда":
    case "Knife":
    case "Нож":
    case "Gloves":
    case "Перчатки":
      return "bg-rare7"; // Коричневый(золотой)
    default:
      return "bg-rare8"; // коричнево-белый
  }
};

export const getSliderItemfon = (rarity: string): JSX.Element => {
  const baseClass =
    "absolute w-[110%] max-w-[110%] top-[-5%] left-1/2 transform -translate-x-1/2 z-1";

  switch (rarity) {
    case "Extraordinary":
    case "Master":
    case "Covert":
    case "экстраординарного типа":
    case "Мастерский":
    case "Тайное":
      return (
        <Img
          src="/images/cases/fon-r1.svg"
          alt="fon-red"
          className={baseClass}
        />
      );
    case "Remarkable":
    case "Exceptional":
    case "Restricted":
    case "примечательного типа":
    case "Исключительный":
    case "Запрещённое":
      return (
        <Img
          src="/images/cases/fon-r2.svg"
          alt="fon-purple"
          className={baseClass}
        />
      );
    case "High Grade":
    case "Distinguished":
    case "Mil-Spec Grade":
    case "высшего класса":
    case "Заслуженный":
    case "Армейское качество":
      return (
        <Img
          src="/images/cases/fon-r3.svg"
          alt="fon-blue"
          className={baseClass}
        />
      );
    case "Industrial Grade":
    case "Промышленное качество":
      return (
        <Img
          src="/images/cases/fon-r4.svg"
          alt="fon-light-blue"
          className={baseClass}
        />
      );
    case "Exotic":
    case "Superior":
    case "Classified":
    case "экзотичного вида":
    case "Превосходный":
    case "Засекреченное":
      return (
        <Img
          src="/images/cases/fon-r5.svg"
          alt="fon-purple"
          className={baseClass}
        />
      );
    case "Base Grade":
    case "Consumer Grade":
    case "Default":
    case "базового класса":
    case "Ширпотреб":
    case "Стандартное":
      return (
        <Img
          src="/images/cases/fon-r6.svg"
          alt="fon-light-beige"
          className={baseClass}
        />
      );
    case "Contraband":
    case "Контрабанда":
    case "Knife":
    case "Нож":
    case "Gloves":
    case "Перчатки":
      return (
        <Img
          src="/images/cases/fon-r7.svg"
          alt="fon-gold"
          className={baseClass}
        />
      );
    default:
      return (
        <Img
          src="/images/cases/fon-r7.svg"
          alt="fon-default"
          className={baseClass}
        />
      );
  }
};

export const getSliderVerticalItemfon = (rarity: string): JSX.Element => {
  const baseClass = "fon-img";

  switch (rarity) {
    case "Extraordinary":
    case "Master":
    case "Covert":
    case "экстраординарного типа":
    case "Мастерский":
    case "Тайное":
      return (
        <Img
          src="/images/cases/fon-r1.svg"
          alt="fon-red"
          className={baseClass}
        />
      );
    case "Remarkable":
    case "Exceptional":
    case "Restricted":
    case "примечательного типа":
    case "Исключительный":
    case "Запрещённое":
      return (
        <Img
          src="/images/cases/fon-r2.svg"
          alt="fon-purple"
          className={baseClass}
        />
      );
    case "High Grade":
    case "Distinguished":
    case "Mil-Spec Grade":
    case "высшего класса":
    case "Заслуженный":
    case "Армейское качество":
      return (
        <Img
          src="/images/cases/fon-r3.svg"
          alt="fon-blue"
          className={baseClass}
        />
      );
    case "Industrial Grade":
    case "Промышленное качество":
      return (
        <Img
          src="/images/cases/fon-r4.svg"
          alt="fon-light-blue"
          className={baseClass}
        />
      );
    case "Exotic":
    case "Superior":
    case "Classified":
    case "экзотичного вида":
    case "Превосходный":
    case "Засекреченное":
      return (
        <Img
          src="/images/cases/fon-r5.svg"
          alt="fon-purple"
          className={baseClass}
        />
      );
    case "Base Grade":
    case "Consumer Grade":
    case "Default":
    case "базового класса":
    case "Ширпотреб":
    case "Стандартное":
      return (
        <Img
          src="/images/cases/fon-r6.svg"
          alt="fon-light-beige"
          className={baseClass}
        />
      );
    case "Contraband":
    case "Контрабанда":
    case "Knife":
    case "Нож":
    case "Gloves":
    case "Перчатки":
      return (
        <Img
          src="/images/cases/fon-r7.svg"
          alt="fon-gold"
          className={baseClass}
        />
      );
    default:
      return (
        <Img
          src="/images/cases/fon-r7.svg"
          alt="fon-default"
          className={baseClass}
        />
      );
  }
};
