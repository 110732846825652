import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center mt-10 mb-10 text-lg h-screen">
      <p className="text-8xl">404</p>
      <h1>{t("not-found")}</h1>
    </div>
  );
};

export default NotFound;
