import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import CaseImg from "./CaseImg";

const steamLoginUrl = `${process.env.REACT_APP_BASE_URL}steam/login`;

interface BlockAuthorizedProps {
  caseImg: string;
}

export default function BlockAuthorized({ caseImg }: BlockAuthorizedProps) {
  const { t } = useTranslation();
  return (
    <>
      <CaseImg count={1} caseImg={caseImg} />
      <div className="flex flex-col sm:flex-row justify-center items-center gap-2">
        <div className="w-full sm:w-auto flex justify-center sm:justify-between items-center gap-c7 px-8 py-3 sm:py-c21 border border-solid border-red7 text-red4 text-13 sm:text-15 font-bold rounded-30p">
          {t("not-authorized")}
        </div>
        <a href={steamLoginUrl} className="w-full sm:w-auto">
          <button className="w-full sm:w-auto flex justify-center sm:justify-between items-center max-h-c30 px-c34 py-3 sm:py-4 bg-red4 hover:bg-red5 text-dark3 text-13 sm:text-15.28 font-bold rounded-30p">
            {t("login-steam")}
            <div className="inline-block w-5 h-5 sm:w-7 sm:h-7 ml-2">
              <Img
                src="/images/panel-header/login-steam.svg"
                alt="login-steam"
                className="object-cover w-full h-full"
                loader={<span className="inline-block w-full h-full ml-2" />}
              />
            </div>
          </button>
        </a>
      </div>
    </>
  );
}
