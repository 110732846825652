export const payGift = [
  {
    price: 1,
  },
  {
    price: 3,
  },
  {
    price: 5,
  },
  {
    price: 10,
  },
  {
    price: 20,
  },
  {
    price: 30,
  },
  {
    price: 50,
  },
  {
    price: 100,
  },
];
