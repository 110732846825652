import { Img } from "react-image";
import { SkinType } from "../../../utils/types";
import { getBgColorClass } from "../../../utils/rarityFonColor";
import { useState } from "react";

interface CasesProps {
  casesItem: SkinType;
  onOpenReplaceSkinModal?: () => void;
  onOpenSellSkinModal?: () => void;
}

const UserCardProfile: React.FC<CasesProps> = ({ casesItem }) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  // Получаем класс фона для текущей карточки
  const bgColorClass = getBgColorClass(casesItem.rarity);

  return (
    <>
      <div
        className={`relative aspect-[127/101.25] flex flex-col items-center rounded-14p pb-6 px-3 overflow-hidden ${bgColorClass}`}
        onMouseEnter={() => setHoveredItem(casesItem.id)}
        onMouseLeave={() => setHoveredItem(null)}
      >
        <span
          className={`absolute text-12 text-yellow4 font-semibold top-3 right-3 ${
            hoveredItem === casesItem.id && "opacity-20"
          }`}
        >
          ${casesItem.price}
        </span>
        <Img
          src={casesItem.image_url}
          alt={casesItem.skin_name}
          // width={160}
          // height={116}
          className={`${hoveredItem === casesItem.id && "opacity-20"}`}
        />
        <div
          className={`absolute left-3.5 bottom-3.5 ${
            hoveredItem === casesItem.id && "hidden md:hidden"
          }`}
        >
          <div className="flex gap-c3 mb-1">
            {casesItem.wear_en && (
              <span className="text-10.13 text-grey4 font-semibold border rounded-15p px-1.5 py-0.5">
                {casesItem.wear_en}
              </span>
            )}
            {casesItem.stattrak && (
              <span className="text-10.13 text-red9 font-semibold border rounded-15p px-1.5 py-0.5">
                ST
              </span>
            )}
          </div>
          <p className="text-12 text-cases-name font-medium">
            {casesItem.weapon}
          </p>
          <p className="text-13 text-cust-white font-medium">
            {casesItem.skin_name}
          </p>
        </div>
        <Img
          src="/images/header/bottomimg.png"
          alt="bottomimg"
          width={88}
          height={77}
          className={`absolute left-0 transition-all duration-500 ease ${
            hoveredItem === casesItem.id
              ? "top-16 opacity-1"
              : "top-40 opacity-0"
          }`}
        />
        <div className="blur"></div>
        <p
          className={`absolute left-3.5 transition-all duration-500 ease text-12 text-cust-white font-medium" ${
            hoveredItem === casesItem.id ? "bottom-3.5" : "-bottom-8"
          }`}
        >
          {casesItem.name_id}
        </p>
      </div>
    </>
  );
};

export { UserCardProfile };
