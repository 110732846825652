import { Img } from "react-image";

interface BlockXProps {
  count: number;
  caseImg: string;
}

export default function CaseImg({ count, caseImg }: BlockXProps) {
  return (
    <div className="w-full flex items-center justify-evenly mb-2 sm:mb-c30 bg-dark1 rounded-20p">
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          className="inline-block min-w-c148 h-c148 md:w-c262 md:h-c262"
        >
          <Img
            key={index}
            src={caseImg}
            alt="back"
            className="object-contain w-full h-full"
            loader={<span className="inline-block w-full h-full" />}
          />
        </div>
      ))}
    </div>
  );
}
