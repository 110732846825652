import { useState } from "react";
import { Img } from "react-image";
import { SkinType } from "../../../utils/types";
import { getBgColorClass } from "../../../utils/rarityFonColor";
import { useProfileStore } from "../../../zustand/stores/profileStore";
import { getExchangeSkins } from "../../../api/profile/getExchangeSkins";
import { useNotification } from "../../../context/NotificationContext";
import { withdrawSkin } from "../../../api/profile/withdrawSkin";
import { useUserStore } from "../../../zustand/stores/userStore";

interface CasesProps {
  casesItem: SkinType;
  onOpenReplaceSkinModal: (
    item: SkinType,
    exchangeableSkins: SkinType[]
  ) => void;
  onOpenSellSkinModal: (item: SkinType) => void;
}

const MyCardProfile: React.FC<CasesProps> = ({
  casesItem,
  onOpenReplaceSkinModal,
  onOpenSellSkinModal,
}) => {
  const { addNotification } = useNotification();
  const symbol = useUserStore.use.getSymbol()();
  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);
  const [isExchangeLoading, setIsExchangeLoading] = useState(false);

  // Получаем необходимые данные из хранилища
  const addWithdrawnSkin = useProfileStore((state) => state.addWithdrawnSkin);
  const recentlySoldSkins = useProfileStore((state) => state.recentlySoldSkins);

  // Получаем класс фона для текущей карточки
  const bgColorClass = getBgColorClass(casesItem.rarity);

  // Состояния для отображения наложений
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Определяем текущий статус скина
  const currentStatus =
    recentlySoldSkins.all === "SOLD" ||
    recentlySoldSkins[casesItem.id] === "SOLD"
      ? "SOLD"
      : recentlySoldSkins[casesItem.id] === "EXCHANGE"
      ? "EXCHANGE"
      : recentlySoldSkins[casesItem.id] === "WITHDRAW"
      ? "WITHDRAW"
      : casesItem.status;

  // const handleToggleOverlay = () => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setIsLoaded(true);
  //   }, 2000);
  // };

  const handleWithdrawButton = () => {
    setIsLoaded(false);
    addWithdrawnSkin(casesItem.id); // Оптимистично меняем статус в хранилище на WITHDRAW
  };

  const handleWithdrawSkin = async () => {
    setIsWithdrawLoading(true);
    try {
      const response = await withdrawSkin(casesItem.id);
      console.log(casesItem.id);
      if (response.success && response.status === "pending") {
        setIsLoading(true);
        // setTimeout(() => {
        //   setIsLoading(false);
        //   setIsLoaded(true);
        // }, 2000);
      } else {
        console.error(response.message || "Ошибка при выводе скина", "error");
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Skin is not available for withdrawal:", error);
        // if ((error as any).message?.id === 2) {
        if (error.message === "Скин не найден или недоступен.") {
          // Открываем модальное окно замены скина без запуска лоадера
          handleOpenReplaceSkinModal(false);
        } else {
          const errorMessage =
            error.message || "Skin is not available for withdrawal.";
          addNotification(errorMessage, "error");
        }
      } else {
        console.error("ESkin is not available for withdrawal:", error);
      }
    } finally {
      setIsWithdrawLoading(false);
    }
  };

  const handleOpenReplaceSkinModal = async (showLoading = true) => {
    if (showLoading) {
      setIsExchangeLoading(true);
    }
    try {
      const exchangeableSkins = await getExchangeSkins(casesItem.id);
      onOpenReplaceSkinModal(casesItem, exchangeableSkins);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error retrieving case data:", error);
        const errorMessage = error.message || "Error retrieving case data -1";
        addNotification(errorMessage, "error");
      } else {
        console.error("Error retrieving case data -2:", error);
      }
    } finally {
      if (showLoading) {
        setIsExchangeLoading(false);
      }
    }
  };

  const handleOpenSellSkinModal = () => {
    onOpenSellSkinModal(casesItem);
  };

  const truncateName = (name: string | null | undefined, maxLength: number) => {
    if (!name) return "";
    if (name.length <= maxLength) return name;
    return `${name.slice(0, maxLength)}...`;
  };

  return (
    <>
      <div
        className={`relative aspect-[127/101.25] flex flex-col items-center rounded-14p pb-6 px-3 ${bgColorClass}`}
      >
        <span className="absolute text-12 text-yellow4 font-semibold top-3 right-3">
          {symbol}
          {casesItem.price}
        </span>

        <Img
          src={casesItem.image_url}
          alt={casesItem.skin_name || "Skin"}
          className="object-cover"
        />

        <div className="absolute left-3 bottom-3">
          <div className="flex gap-c3 mb-1">
            {casesItem.wear_en && (
              <span className="text-10.13 text-grey4 font-semibold border rounded-15p px-1.5 py-0.5">
                {casesItem.wear_en}
              </span>
            )}
            {casesItem.stattrak && (
              <span className="text-10.13 text-red9 font-semibold border rounded-15p px-1.5 py-0.5">
                ST
              </span>
            )}
          </div>
          <p className="text-12 text-cases-name font-medium">
            {casesItem.weapon}
          </p>
          <p className="text-13 text-cust-white font-medium">
            {truncateName(casesItem.skin_name, 9)}
          </p>
        </div>

        {currentStatus === "SOLD" && (
          <div className="flex justify-center items-center absolute top-3 left-3 w-c30 h-c30 rounded-full cursor-pointer opacity-80">
            <Img
              src="/images/profile/sell-skin.svg"
              alt="sell"
              width={30}
              height={30}
            />
          </div>
        )}
        {currentStatus === "EXCHANGE" && (
          <div className="flex justify-center items-center absolute top-3 left-3 w-c30 h-c30 rounded-full cursor-pointer opacity-80">
            <Img
              src="/images/profile/replace-skin.svg"
              alt="replace"
              width={30}
              height={30}
            />
          </div>
        )}
        {currentStatus === "WITHDRAW" && (
          <div className="flex justify-center items-center absolute top-3 left-3 w-c30 h-c30 rounded-full cursor-pointer opacity-80">
            <Img
              src="/images/profile/withdraw-steam.svg"
              alt="take"
              width={30}
              height={30}
            />
          </div>
        )}
        {currentStatus === "DEFAULT" && (
          <>
            <div
              className="flex justify-center items-center absolute bottom-12 right-3 w-c30 h-c30 rounded-full cursor-pointer"
              onClick={() => handleOpenReplaceSkinModal(true)}
            >
              {isExchangeLoading ? (
                <div className="w-5 h-5 border-t-2 border-b-2 border-gray-200 rounded-full animate-spin"></div>
              ) : (
                <Img
                  src="/images/profile/replace-skin.svg"
                  alt="replace"
                  width={30}
                  height={30}
                />
              )}
            </div>
            <div
              className="flex justify-center items-center absolute bottom-3 right-12 w-c30 h-c30 rounded-full cursor-pointer"
              onClick={handleOpenSellSkinModal}
            >
              <Img
                src="/images/profile/sell-skin.svg"
                alt="sell"
                width={30}
                height={30}
              />
            </div>
            <div
              className="flex justify-center items-center absolute bottom-3 right-3 w-c30 h-c30 rounded-full cursor-pointer"
              onClick={handleWithdrawSkin}
            >
              {isWithdrawLoading ? (
                <div className="w-5 h-5 border-t-2 border-b-2 border-gray-200 rounded-full animate-spin"></div>
              ) : (
                <Img
                  src="/images/profile/take-skin.svg"
                  alt="take"
                  width={30}
                  height={30}
                />
              )}
            </div>
          </>
        )}
        {isLoading && (
          <div className="absolute inset-0 bg-black1 flex justify-center items-center">
            <div className="flex flex-col items-center">
              <div className="flex gap-0.5">
                {/* <Img
      src="/images/profile/clock.svg"
      alt="Loaded"
      width={22}
      height={22}
      className="mb-1"
    /> */}
                <Img
                  src="/images/profile/take-loading.svg"
                  alt="Loading"
                  width={40}
                  height={40}
                  className="mb-1"
                />
                {/* <div className="w-7 h-7 border-t-2 border-b-2 border-gray-200 rounded-full animate-spin mb-5"></div> */}
              </div>
              <p className="items-center text-grey3 text-12 font-medium">
                {/* Waiting for the seller */} ЗАКУПАЕМ ПРЕДМЕТ
              </p>
            </div>
          </div>
        )}
        {!isLoading && isLoaded && (
          <div className="absolute inset-0 bg-black1 flex justify-center items-center px-1">
            <div className="flex flex-col items-center">
              <button
                className="w-full lg:w-auto flex justify-center lg:justify-between items-center px- lg:px-2.5 py-2 lg:py-2 bg-green3 hover:bg-green4 text-dark8 text-12.18 lg:text-13.20 font-bold rounded-30p"
                onClick={handleWithdrawButton}
              >
                <span>Принять трейд</span>
                <div className="inline-block w-4 h-4 lg:w-5 lg:h-5 ml-1">
                  <Img
                    src="/images/profile/withdraw-button.svg"
                    alt="cart"
                    className="object-cover w-full h-full"
                    loader={
                      <span className="inline-block w-full h-full mr-1.5" />
                    }
                  />
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export { MyCardProfile };
