import { useState } from "react";
import { Img } from "react-image";
import { Link } from "react-router-dom";

const SocSeti = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  return (
    <>
      <Link to="#">
        <Img
          src={
            hoveredItem === "youtube"
              ? "/images/panel-header/youtube-hover.svg"
              : "/images/panel-header/youtube.svg"
          }
          alt="youtube"
          width={22}
          height={22}
          loader={
            <span
              style={{
                width: 22,
                height: 22,
              }}
            />
          }
          className="cursor-pointer"
          onMouseEnter={() => setHoveredItem("youtube")}
          onMouseLeave={() => setHoveredItem(null)}
        />
      </Link>
      <Link to="#">
        <Img
          src={
            hoveredItem === "telegram"
              ? "/images/panel-header/telegram-hover.svg"
              : "/images/panel-header/telegram.svg"
          }
          alt="telegram"
          width={22}
          height={22}
          loader={
            <span
              style={{
                width: 22,
                height: 22,
              }}
            />
          }
          className="cursor-pointer"
          onMouseEnter={() => setHoveredItem("telegram")}
          onMouseLeave={() => setHoveredItem(null)}
        />
      </Link>
      <Link to="#">
        <Img
          src={
            hoveredItem === "vk"
              ? "/images/panel-header/vk-hover.svg"
              : "/images/panel-header/vk.svg"
          }
          alt="vk"
          width={22}
          height={22}
          loader={
            <span
              style={{
                width: 22,
                height: 22,
              }}
            />
          }
          className="cursor-pointer"
          onMouseEnter={() => setHoveredItem("vk")}
          onMouseLeave={() => setHoveredItem(null)}
        />
      </Link>
      <Link to="#">
        <Img
          src={
            hoveredItem === "steam"
              ? "/images/panel-header/steam-hover.svg"
              : "/images/panel-header/steam.svg"
          }
          alt="steam"
          width={22}
          height={22}
          loader={
            <span
              style={{
                width: 22,
                height: 22,
              }}
            />
          }
          className="cursor-pointer"
          onMouseEnter={() => setHoveredItem("steam")}
          onMouseLeave={() => setHoveredItem(null)}
        />
      </Link>
    </>
  );
};

export { SocSeti };
