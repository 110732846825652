export const payCards = [
  {
    image: "/images/deposit/cards/alfa.svg",
    label: "ALFA BANK",
  },
  {
    image: "/images/deposit/cards/mir.svg",
    label: "MIR",
  },
  {
    image: "/images/deposit/cards/visa.svg",
    label: "VISA",
  },
  {
    image: "/images/deposit/cards/sber.svg",
    label: "SBER",
  },
  {
    image: "/images/deposit/cards/tinkoff.svg",
    label: "TINKOFF",
  },
  {
    image: "/images/deposit/cards/paypass.svg",
    label: "PAYPASS",
  },
  {
    image: "/images/deposit/cards/robokassa.svg",
    label: "ROBOKASSA",
  },
];
