import "./home.css";
import { useEffect, useState, useMemo } from "react";
import { CaseCard } from "../../components/cases/caseCard/CaseCard";
import Filtr from "../../components/filtr/Filtr";
import { Giveaways } from "../../components/giveaways/Giveaways";
import { getCases } from "../../api/apiGetCaseData";
import { useNotification } from "../../context/NotificationContext";
import SkeletonCaseCard from "../../components/cases/caseCard/SkeletonCaseCard";
import { useUserStore } from "../../zustand/stores/userStore";
import { CaseItemType, CasesType, CategoryType } from "../../utils/types";
import { useTranslation } from "react-i18next";

interface Filters {
  searchQuery: string;
  available: boolean;
  priceRange: [number, number] | null;
}

export default function Home() {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const userBalance = useUserStore.use.getBalance()() ?? 0;

  const [filters, setFilters] = useState<Filters>({
    searchQuery: "",
    available: false,
    priceRange: [0, 40000],
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const caseData: CasesType = await getCases();
        if (caseData.success && Array.isArray(caseData.data)) {
          setCategories(caseData.data);
        } else {
          throw new Error(
            "Failed to fetch case data or invalid data structure"
          );
        }
      } catch (error: unknown) {
        console.error("Error retrieving case data:", error);
        if (error instanceof Error) {
          addNotification(error.message, "error");
        } else {
          addNotification("An unknown error occurred", "error");
        }
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const updateFilters = (newFilters: Partial<Filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const filteredCategories = useMemo(() => {
    if (loading) return [];
    return categories.map((category) => ({
      ...category,
      cases: category.cases.filter(
        (item: CaseItemType) =>
          item.name.toLowerCase().includes(filters.searchQuery.toLowerCase()) &&
          (!filters.available || item.price <= userBalance) &&
          (!filters.priceRange ||
            (item.price >= filters.priceRange[0] &&
              item.price <= filters.priceRange[1]))
      ),
    }));
  }, [categories, filters, userBalance, loading]);

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <h2 className="text-24 text-white font-bold mt-12 mb-8 text-center">
            {t("loading")}
          </h2>
          <div className="flex items-center justify-center flex-wrap gap-y-5">
            <SkeletonCaseCard />
            <SkeletonCaseCard />
            <SkeletonCaseCard />
          </div>
        </>
      );
    }

    if (
      filteredCategories.length === 0 ||
      filteredCategories.every((category) => category.cases.length === 0)
    ) {
      return (
        <p className="text-white text-center mt-8">Нет доступных кейсов</p>
      );
    }

    return filteredCategories.map((category) => (
      <div key={category.category}>
        <h2 className="text-24 text-white font-bold mt-12 mb-8 text-center">
          {category.category}
        </h2>
        <div className="flex items-center justify-center flex-wrap gap-y-5">
          {category.cases.map((item: CaseItemType) => (
            <CaseCard key={item.id} cardItem={item} />
          ))}
        </div>
      </div>
    ));
  };

  return (
    <main className="flex flex-col items-center w-full md:max-w-main md:mx-auto pt-c30 pb-c66">
      <div className="w-full flex md:flex-col flex-col-reverse lg:flex-row items-center gap-c15 zoom-filtr">
        <Filtr updateFilters={updateFilters} />
        <Giveaways />
      </div>

      {renderContent()}
    </main>
  );
}
