import React from "react";
import { Img } from "react-image";
import { CardProps } from "../types";

const PayItem: React.FC<CardProps> = ({ payItem, isSelected, onSelect }) => {
  return (
    <div
      className={`relative aspect-[115/80] py-c18 px-c17 md:py-c30 md:px-c23 rounded-xl md:rounded-14p cursor-pointer transition-colors duration-300
        ${
          isSelected
            ? "bg-select-deposit border border-red3 box-border"
            : "bg-label1 hover:bg-hover-deposit"
        }`}
      onClick={() => onSelect(payItem)}
    >
      <div className="transition-transform transform-gpu hover:scale-110">
        <Img src={payItem.image} alt={payItem.label} className="object-cover" />
      </div>

      {payItem.label && (
        <span className="absolute top-2.5 right-2.5 text-7 md:text-9 text-white font-bold md:font-semibold bg-bglabel border-1 border-solid border-label2 rounded-20p py-1 px-1.5 md:py-1.5 md:px-2">
          {payItem.label}
        </span>
      )}

      {isSelected && (
        <div className="absolute bottom-c7 right-c7 transition-opacity duration-300 opacity-100">
          <Img
            src="/images/deposit/switch.svg"
            alt="Selected"
            width={22}
            height={22}
            className="object-contain"
          />
        </div>
      )}
    </div>
  );
};

export { PayItem };
