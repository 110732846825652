import React from "react";

interface SwitchBlockProps {
  isChecked?: boolean;
  handleToggle?: () => void;
}

const SwitchBlock: React.FC<SwitchBlockProps> = ({
  isChecked,
  handleToggle,
}) => {
  return (
    <div
      className={`relative inline-block w-c34 h-6 ${
        isChecked ? "bg-green5" : "bg-dark5"
      } rounded-full transition-colors cursor-pointer`}
      onClick={handleToggle}
    >
      <div
        className={`absolute m-c3 top-0.5 left-0.5 w-3.5 h-3.5 bg-[#646e8b] rounded-full transition-transform transform ${
          isChecked ? "translate-x-c9 bg-green4" : ""
        }`}
      ></div>
    </div>
  );
};

export default SwitchBlock;
