import { useState } from "react";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import { getBgColorClass } from "../../../utils/rarityFonColor";

interface CardHeaderItem {
  image: string;
  name: string;
  caseName: string;
  avatar: string;
  nickName: string;
  id: number;
  rarity: string;
}

interface CardHeaderProps {
  cardHeaderItem: CardHeaderItem;
}

const CardHeader: React.FC<CardHeaderProps> = ({ cardHeaderItem }) => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  // Получаем класс фона для текущей карточки
  const bgColorClass = getBgColorClass(cardHeaderItem.rarity);
  return (
    <div
      key={cardHeaderItem.id}
      className={`relative flex justify-center flex-shrink-0 md:mr-c5 mr-0.5 w-20 md:w-c140 rounded-14p cursor-pointer ${bgColorClass}`}
      onMouseEnter={() => setHoveredItem(cardHeaderItem.id)}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <div
        className={`flex items-center absolute left-0 transition-all duration-500 ease ml-2.5 ${
          hoveredItem === cardHeaderItem.id
            ? "top-1 md:top-2.5 z-10"
            : "top-ot-30"
        }`}
      >
        <Link to={`/user/${cardHeaderItem.id}`}>
          <Img
            src={cardHeaderItem.avatar}
            alt="topumg"
            width={22}
            height={22}
            className="mr-2 md:w-[30px] md:h-[30px]"
          />
        </Link>
        <p className="text-11 text-cust-white hidden md:block">
          {cardHeaderItem.nickName}
        </p>
      </div>
      <div
        className={`flex w-full flex-col justify-center items-center rounded-14p transition-all duration-1200 ease ${
          hoveredItem === cardHeaderItem.id &&
          "bg-hover-fon1  opacity-85 mt-0 mb-0"
        } mt-0 mb-0`}
      >
        <Img
          src={cardHeaderItem.image}
          alt="item"
          width={110}
          height={80}
          className={`md:w-[110px] md:h-20 w-[66px] h-12 flex flex-col justify-center items-center ${
            hoveredItem === cardHeaderItem.id && "opacity-5"
          }`}
        />
        <p
          className={`text-xs font-medium hidden md:block ${
            hoveredItem === cardHeaderItem.id && "hidden md:hidden"
          }`}
        >
          {cardHeaderItem.name}
        </p>
        <Img
          src="/images/header/bottomimg.png"
          alt="bottomimg"
          width={88}
          height={77}
          className={`absolute right-0 transition-all duration-500 ease ${
            hoveredItem === cardHeaderItem.id
              ? "z-10 top-1 md:top-34"
              : "top-28"
          }`}
        />
        <div className="blur"></div>
        <p
          className={`flex items-center z-20 justify-center w-auto md:w-c120 rounded-13p border-solid border-white/[0.06] border px-1 md:px-0 ${
            hoveredItem === cardHeaderItem.id
              ? "bg-white/[0.04] backdrop-blur-md absolute md:relative bottom-0 mb-0 md:mb-c9 h-4 md-c23"
              : "bg-fon1 hidden relative"
          }`}
        >
          <span className="text-10 z-30 text-blue">
            {cardHeaderItem.caseName}
          </span>
        </p>
      </div>
    </div>
  );
};

export { CardHeader };
