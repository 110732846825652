import "./profile.css";
import ProfileTop from "../../components/profile/ProfileTop";
import ItemsProfile from "../../components/profile/ItemsProfile";
import ReplaceSkinModal from "../../components/modals/ReplaceSkinModal";
import SellSkinModal from "../../components/modals/SellSkinModal";
import AllSellSkinModal from "../../components/modals/SellAllSkinModal";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import { useEffect, useState } from "react";
import { getSkinsForProfile } from "../../api/profile/getSkinsForProfile";
import { useUserStore } from "../../zustand/stores/userStore";
import { useProfileStore } from "../../zustand/stores/profileStore";
import { useNotification } from "../../context/NotificationContext";
import { SkinType } from "../../utils/types";

export default function Profile() {
  const { addNotification } = useNotification();
  const {
    isReplaceSkinModalOpen,
    isSellSkinModalOpen,
    isAllSellSkinModalOpen,
  } = useModalsStore();

  const { setSkins, setLoading, setHasMore, setPage, setSkinCount, isDefault } =
    useProfileStore();

  const reset = useUserStore.use.reset();

  const [exchangeableSkins, setExchangeableSkins] = useState<SkinType[]>([]);

  useEffect(() => {
    const resetRecentlySoldSkins =
      useProfileStore.getState().resetRecentlySoldSkins;

    const fetchSkins = async () => {
      try {
        setLoading(true);
        setPage(1);
        resetRecentlySoldSkins(); // Сброс оптимистичного массива скинов перед загрузкой новых данных с сервера.
        const { skins, hasMore, skin_count } = await getSkinsForProfile(
          1,
          isDefault
        );
        setSkins(skins, false); // Заменяем существующие скины
        setHasMore(hasMore);
        setSkinCount(skin_count);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Error getting skins in profile:", error);
          const errorMessage =
            error.message || "An unexpected error occurred -1";
          addNotification(errorMessage, "error");
          if (
            error.message.includes("no_auth") ||
            error.message.includes("401")
          ) {
            reset();
          }
        } else {
          console.error("Error getting skins in profile: -2", error);
        }
        setSkins([]);
        setHasMore(false);
        setSkinCount(0);
      } finally {
        setLoading(false);
      }
    };

    fetchSkins();

    return () => {
      resetRecentlySoldSkins(); // Сброс оптимистичного массива скинов при размонтировании компонента.
    };
  }, []);

  return (
    <main className="flex flex-col w-full md:max-w-main md:mx-auto pt-4 md:pt-c30 pb-c26 md:pb-c66 main-mx">
      <ProfileTop />
      <ItemsProfile setExchangeableSkins={setExchangeableSkins} />
      {isReplaceSkinModalOpen && (
        <ReplaceSkinModal exchangeableSkins={exchangeableSkins} />
      )}
      {isSellSkinModalOpen && <SellSkinModal />}
      {isAllSellSkinModalOpen && <AllSellSkinModal />}
    </main>
  );
}
