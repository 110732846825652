import BlokBalance from "./blocks/BlockBalance";
import BlockAuthorized from "./blocks/BlockAuthorized";
import BlockLimit from "./blocks/BlockLimit";
import SliderBlock from "./blocks/SliderBlock";
import { useUserStore } from "../../zustand/stores/userStore";
import { SkinTypeSlider } from "../../utils/types";

interface CaseBlocksProps {
  skins: SkinTypeSlider[];
  name: string;
  priceCase: number;
  caseImg: string;
}

export default function CaseBlocks({
  skins,
  name,
  priceCase,
  caseImg,
}: CaseBlocksProps) {
  const isLoggedIn = useUserStore.use.isLoggedIn();
  const Limit: number = 10;
  const balance = useUserStore.use.getBalance()() ?? 0;

  console.log("баланс с бека:", balance);

  // Преобразуем баланс и цену кейса в числа с двумя десятичными знаками
  const balanceFixed = parseFloat(balance.toFixed(2));
  const priceCaseFixed = parseFloat(priceCase.toFixed(2));

  console.log("баланс с бека (округленный):", balanceFixed);
  console.log("priceCase (округленная):", priceCaseFixed);

  return (
    <>
      {!isLoggedIn && <BlockAuthorized caseImg={caseImg} />}
      {isLoggedIn && Limit === 0 && <BlockLimit caseImg={caseImg} />}
      {isLoggedIn && Limit > 0 && balanceFixed >= priceCaseFixed && (
        <SliderBlock
          skins={skins}
          name={name}
          priceCase={priceCase}
          caseImg={caseImg}
        />
      )}
      {isLoggedIn && Limit > 0 && balanceFixed < priceCaseFixed && (
        <BlokBalance
          caseImg={caseImg}
          noBalance={parseFloat((priceCaseFixed - balanceFixed).toFixed(2))}
        />
      )}
    </>
  );
}
