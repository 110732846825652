import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../../pages/layout";
import Home from "../../pages/home/home";
import Case from "../../pages/case/[name]/cases";
import Contacts from "../../pages/contacts/contacts";
import Contracts from "../../pages/contracts/contracts";
import Deposit from "../../pages/deposit/deposit";
import Faq from "../../pages/faq/faq";
import Giveaways from "../../pages/giveaways/giveaways";
import Profile from "../../pages/profile/profile";
import SecurityPolicy from "../../pages/security-policy/security-policy";
import Support from "../../pages/support/support";
import TermsOfUse from "../../pages/terms-of-use/terms-of-use";
import Upgrade from "../../pages/upgrade/upgrade";
import User from "../../pages/user/[id]/user";
import PrivateRoute from "../HOC/PrivateRoute";
import ErrorModal from "../modals/ErrorModal";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import useFetchUserData from "../../api/fetchUserData";
import NotFound from "../NotFound";

function App() {
  useFetchUserData();

  const isErrorModalOpen = useModalsStore((state) => state.isErrorModalOpen);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="case/:name" element={<Case />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="contracts" element={<Contracts />} />
          <Route
            path="deposit"
            element={
              <PrivateRoute>
                <Deposit />
              </PrivateRoute>
            }
          />
          <Route path="faq" element={<Faq />} />
          <Route path="giveaways" element={<Giveaways />} />
          <Route
            path="profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route path="security-policy" element={<SecurityPolicy />} />
          <Route path="support" element={<Support />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="upgrade" element={<Upgrade />} />
          <Route path="user/:id" element={<User />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      {isErrorModalOpen && <ErrorModal />}
    </Router>
  );
}

export default App;
