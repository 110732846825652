import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

export default function Promocode() {
  const { t } = useTranslation();

  // Захардкоженные промокоды и их соответствующие скидки
  const promoCodes: { [key: string]: string } = {
    PROMO5: "5%",
    PROMO10: "10%",
    PROMO25: "25%",
    PROMO50: "50%",
    PROMO100: "100%",
  };

  const [inputValue, setInputValue] = useState<string>("");
  const [isCodeValid, setIsCodeValid] = useState<boolean>(false);
  const [discount, setDiscount] = useState<string>("");
  const [existingPromoCode, setExistingPromoCode] = useState<string>("");
  const [isReplacing, setIsReplacing] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    setInputValue(value);
    setIsCodeValid(!!promoCodes[value]);
  };

  const handleApplyClick = () => {
    if (discount && inputValue !== existingPromoCode) {
      setIsReplacing(true); // Показать предупреждение о замене промокода
    } else if (isCodeValid) {
      setDiscount(promoCodes[inputValue]); // Применить новый промокод
      setExistingPromoCode(inputValue); // Сохранить новый промокод как текущий
    }
  };

  const handleReplaceConfirm = () => {
    setDiscount(promoCodes[inputValue]); // Применить новый промокод
    setExistingPromoCode(inputValue); // Обновить текущий промокод
    setIsReplacing(false); // Скрыть предупреждение
  };

  const handleReplaceCancel = () => {
    setInputValue(existingPromoCode); // Вернуть старый промокод в поле ввода
    setIsReplacing(false); // Скрыть предупреждение
  };

  return (
    <>
      {isReplacing ? (
        <div className="relative w-full xl:w-c452 flex flex-col gap-5 items-center justify-center text-center bg-dark4 rounded-14p py-8 px-12 md:py-12 md:px-24 rounded-inward order-2 md:order-1">
          <p className="text-white text-14 md:text-13.18">
            {t("replace-promocode")} “
            <span className="font-bold">{existingPromoCode}</span>” ?
          </p>
          <div className="flex gap-2">
            <button
              onClick={handleReplaceCancel}
              className="bg-dark5 text-white text-12 font-bold px-5 py-2 rounded-30p"
            >
              {t("no")}
            </button>
            <button
              onClick={handleReplaceConfirm}
              className="bg-green3 text-dark3 text-12 font-bold px-5 py-2 rounded-30p"
            >
              {t("yes")}
            </button>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`relative w-full xl:w-c452 flex flex-col gap-y-4 items-start bg-fon-pay ${
              discount ? "rounded-t-14p" : "rounded-14p"
            } p-c14 md:p-5 rounded-inward order-2 md:order-1`}
          >
            <Img
              src="/images/deposit/dep.svg"
              alt="coupon"
              width={169.844}
              height={154.201}
              className="absolute right-0 top-0"
            />
            <div className="flex items-center text-18 text-white font-extrabold">
              <Img
                src="/images/deposit/coupon.svg"
                alt="coupon"
                width={26}
                height={26}
                loader={
                  <span
                    style={{
                      width: 26,
                      height: 26,
                    }}
                    className="mr-2"
                  />
                }
                className="mr-2"
              />
              {t("promocode")}
            </div>
            <div className="w-full justify-between flex items-center gap-1.5 md:gap-2">
              <input
                type="text"
                name="q"
                placeholder={t("enter-promocode")}
                aria-label="Search cases"
                value={inputValue}
                onChange={handleInputChange}
                className="w-full h-10 md:h-c50 pl-c18 pr-4 py-c13 text-12 md:text-13.18 text-white bg-label1 hover:bg-h-label1 rounded-30p focus:outline-none focus:bg-f-label1"
              />
              <button
                type="submit"
                onClick={handleApplyClick}
                className={`w-c130 h-10 md:h-c50 flex-none flex items-center justify-center gap-1.5 px-5 py-c13 rounded-30p z-10 ${
                  isCodeValid ? "bg-grey4 opacity-100" : "bg-grey3 opacity-40"
                }`}
                disabled={!isCodeValid}
              >
                <span className="text-dark4 text-13 md:text-14.24 font-bold md:font-semibold">
                  {t("apply")}
                </span>
                <Img
                  src="/images/deposit/apply-disabled.svg"
                  alt="coupon"
                  width={20}
                  height={20}
                  loader={
                    <span
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  }
                />
              </button>
            </div>
            {discount && <div className="w-full dashed-line"></div>}
          </div>

          {discount && (
            <div className="relative w-full flex items-start bg-fon-pay2 rounded-b-14p p-5 rounded-inward-b order-3 md:order-2">
              <p className="text-10 md:text-13 text-green3 font-medium">
                <span className="text-grey3 font-semibold">{t("bonus")}:</span>{" "}
                {discount} {t("deposit-bonus")} 100 Rub-test
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
}
