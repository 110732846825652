import { useTranslation } from "react-i18next";
import { Img } from "react-image";

const steamLoginUrl = `${process.env.REACT_APP_BASE_URL}steam/login`;

const LoginBlock = () => {
  const { t } = useTranslation();

  if (!steamLoginUrl) {
    console.error("Steam login URL is not defined.");
    return null;
  }

  return (
    <a
      href={steamLoginUrl}
      className="flex justify-between items-center max-h-c30 md:px-5 px-3 md:py-c13 py-2 bg-red4 hover:bg-red5 text-dark3 text-sm font-bold rounded-30p"
    >
      <span className="md:block hidden">{t("login-steam")}</span>
      <span className="md:hidden block">{t("login-steam-mobile")}</span>
      <Img
        src="/images/panel-header/login-steam.svg"
        alt="login-steam"
        width={18}
        height={18}
        loader={<span className="w-c18 h-c18 md:w-6 md:h-6 ml-c7" />}
        className="object-cover md:w-6 md:h-6 ml-c7"
      />
    </a>
  );
};

export { LoginBlock };
