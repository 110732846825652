import Skeleton from "react-loading-skeleton";

const SkeletonCaseCard = () => {
  return (
    <Skeleton
      width={1360}
      height={272}
      duration={1.5}
      highlightColor="#1c2135"
      baseColor="#282e44"
      className="rounded-14p"
    />
  );
};

export default SkeletonCaseCard;
