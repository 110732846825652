import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";

const SkeletonMyCardProfile = () => {
  return (
    <div className="w-full aspect-[127/101.25] relative">
      <Skeleton
        width="100%"
        height="100%"
        duration={1.5}
        highlightColor="#1c2135"
        baseColor="#282e44"
        className="absolute inset-0"
        style={{
          borderRadius: "14px",
        }}
      />
    </div>
  );
};

export default SkeletonMyCardProfile;
