import api from "./api";
import { CaseTypeSlider } from "../utils/types";

export async function getSkinsForCase(name: string): Promise<CaseTypeSlider> {
  return api.request<CaseTypeSlider>(
    `case/info?name_id=${encodeURIComponent(name)}`,
    {
      method: "GET",
    }
  );
}
